import React, { useMemo, useEffect } from "react";
import { Outlet } from "react-router-dom";

// material-ui
import { styled, useTheme } from "@mui/material/styles";
import { AppBar, Box, Container, CssBaseline, Toolbar, useMediaQuery } from "@mui/material";

// project imports
import Breadcrumbs from "../../UI/extend/Breadcrumbs";
// import Customization from "../Customization";
import Header from "./AppHeader";
import HorizontalBar from "./HorizontalBar";
import Sidebar from "./Sidebar";
import navigation from "../../menuList";
import LAYOUT_CONST from "../../../constant";
import useConfig from "../../../Hooks/useConfig";
import { drawerWidth } from "../../../store/constant";
import { openDrawer } from "../../../store/slices/menu";
import { useDispatch, useSelector } from "../../../store";
import { setUser, setUserAddress } from "../../../store/slices/userSlice";
import { APIUrl } from "../../../api/endPoints";
import ApiRequest from "../../../api/ApiRequest";
import useAuth from "../../../Hooks/useAuth";
import { auth } from "../../../firebase";

import toast, { ToastBar, Toaster } from "react-hot-toast";
import { FaTimes } from "react-icons/fa";

// assets
import { IconChevronRight } from "@tabler/icons";
import config from "../../../config/appConfig";

interface MainProps {
	theme: any;
	open: boolean;
	layout: string;
}

const Main = styled("main", { shouldForwardProp: (prop: any) => prop !== "open" })(({ theme, open, layout } : MainProps) => ({
	...theme.typography.mainContent,
	...!open && {
		backgroundColor        : theme.palette.background.default,
		borderBottomLeftRadius : 0,
		borderBottomRightRadius: 0,
		marginRight            : window?.location?.pathname == config?.routes?.landingHome ? "0px" : "30px",
		transition             : theme.transitions.create("margin", {
			easing  : theme.transitions.easing.sharp,
			duration: theme.transitions.duration.shorter + 200
		}),
		[theme.breakpoints.up("md")]: {
			marginLeft : window?.location?.pathname == config?.routes?.landingHome ? "0px" : "30px",
			marginRight: window?.location?.pathname == config?.routes?.landingHome ? "0px" : "30px",
			width      : `calc(100% - ${drawerWidth}px)`,
			marginTop  : layout === "horizontal" ? 135 : 88
		},
		[theme.breakpoints.down("md")]: {
			marginLeft : window?.location?.pathname == config?.routes?.landingHome ? "0px" : "30px",
			width      : `calc(100% - ${drawerWidth}px)`,
			padding    : window?.location?.pathname == config?.routes?.landingHome ? window?.innerWidth < 400 ? "10px" : "0px" : "16px",
			marginRight: window?.location?.pathname == config?.routes?.landingHome ? "0px" : "30px",
			marginTop  : 88
		},
		[theme.breakpoints.down("sm")]: {
			marginLeft : window?.location?.pathname == config?.routes?.landingHome ? "0px" : "10px",
			width      : `calc(100% - ${drawerWidth}px)`,
			padding    : window?.location?.pathname == config?.routes?.landingHome ? window?.innerWidth < 400 ? "10px" : "0px" : "16px",
			marginRight: window?.location?.pathname == config?.routes?.landingHome ? "0px" : "10px",
			marginTop  : 88
		}
	},
	...open && {
		transition: theme.transitions.create("margin", {
			easing  : theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.shorter + 200
		}),
		marginLeft                  : layout === "horizontal" || window?.location?.pathname != config?.routes?.landingHome ? "30px" : "0px",
		padding                     : layout === "horizontal" || window?.location?.pathname != config?.routes?.landingHome ? "30px" : window?.innerWidth < 400 ? "10px" : "0px",
		marginTop                   : layout === "horizontal" ? 135 : 88,
		borderBottomLeftRadius      : 0,
		borderBottomRightRadius     : 0,
		width                       : `calc(100% - ${drawerWidth}px)`,
		[theme.breakpoints.up("md")]: {
			marginTop: layout === "horizontal" ? 135 : 88
		},
		[theme.breakpoints.down("md")]: {
			marginLeft: window?.location?.pathname == config?.routes?.landingHome ? "0px" : "30px",
			marginTop : 88
		},
		[theme.breakpoints.down("sm")]: {
			marginLeft: window?.location?.pathname == config?.routes?.landingHome ? "0px" : "10px",
			marginTop : 88
		}
	}
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {

	const theme = useTheme();
	const user = auth.currentUser;

	const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
	const dispatch = useDispatch();
	const { users, isLoggedIn }: any = useAuth();
	const { id } = useSelector((state: any) => state.user);
	const { drawerOpen } = useSelector((state: any) => state.menu);
	const { drawerType, container, layout } = useConfig();

	// styles

	/*
	 * const [ open, setOpen ] = useState<boolean>(
	 * 	drawerType === LAYOUT_CONST.DEFAULT_DRAWER && drawerOpen ? true : false
	 * );
	 */

	useEffect(() => {

		if (id !== "") return;

		if (!isLoggedIn && users?.accessToken === "") return ;

		ApiRequest("get", APIUrl.getUserInfo).then((response: any) => {

			dispatch(setUser({ user: response }));
			dispatch(setUserAddress({ userAddress: response.userAddress }));

		}).catch((error: any) => {

			console.error(error);

		});

	}, [ user ]);

	useEffect(() => {

		if (drawerType === LAYOUT_CONST.DEFAULT_DRAWER) {

			dispatch(openDrawer(true));
		
		} else {

			dispatch(openDrawer(false));
		
		}
	
	}, [ drawerType ]);

	useEffect(() => {

		if (drawerType === LAYOUT_CONST.DEFAULT_DRAWER) {

			dispatch(openDrawer(true));
		
		}
	
	}, []);

	useEffect(() => {

		if (matchDownMd) {

			dispatch(openDrawer(true));
		
		}
	
	}, [ matchDownMd ]);

	const condition = layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd;

	const header = useMemo(
		() =>
			<Toolbar sx={{ p: condition ? "10px" : "16px" }}>
				<Header />
			</Toolbar>
		,
		[ layout, matchDownMd ]
	);

	return (
		<Box sx={{ display: "flex" }}>
			<CssBaseline />

			{/* header */}
			<AppBar
				position="fixed"
				elevation={0}
				sx={{
					padding   : "0px 2em",
					bgcolor   : "background.default",
					transition: drawerOpen ? theme.transitions.create("width") : "none"
				}}
			>
				{header}
			</AppBar>

			{/* horizontal menu-list bar */}
			{layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd && <HorizontalBar />}

			{/* drawer */}
			{(layout === LAYOUT_CONST.VERTICAL_LAYOUT || matchDownMd) && <Sidebar />}

			{/* main content */}
			<Main sx={{
				backgroundColor: theme.palette.background.default
			}} theme={theme} open={drawerOpen} layout={layout}>
				<Container maxWidth={container ? "lg" : false} {...(!container && { sx: { px: { xs: 0 } } })}>
					{/* breadcrumb */}
					<Breadcrumbs
						separator={IconChevronRight}
						navigation={navigation}
						icon
						title
						rightAlign
					/>
					<Outlet />
				</Container>
			</Main>
			<Toaster toastOptions={{
				duration: 5000,
				position: "bottom-right",
				style   : {
					borderRadius: "10px",
					background  : "#333",
					color       : "#FFF",
					boxShadow   : "0px 3px 16px #00000029"
				}
			}} >
				{t =>
					<ToastBar toast={t}>
						{({ icon, message }) =>
							<>
								{icon}
								{message}
								{t.type !== "loading" &&
                  <FaTimes className="sf-g-toast-close-btn-style" onClick={() => toast.dismiss(t.id)} />
								}
							</>
						}
					</ToastBar>
				}
			</Toaster>
		</Box>
	);

};

export default MainLayout;
