import React, { useCallback, useState, useEffect } from "react";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import Container from "@mui/material/Container";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { useTheme, useMediaQuery } from "@mui/material";
/*
 * import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
 * import { faPenToSquare, faIdCard, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
 * import image from "../../assets/icons/imageIcon.svg";
 */
import { Row, Col } from "rsuite";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
// import Dropzone from "react-dropzone";
import Alert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import config from "../../config/appConfig";
import { APIUrl } from "../../api/endPoints";
import ApiRequest from "../../api/ApiRequest";
import axios from "axios";
import toast from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import { setUser, getUser, setUserAddress } from "../../store/slices/userSlice";
import Form from "react-bootstrap/Form";
import { FaChevronLeft, FaIdCard, FaPenSquare } from "react-icons/fa";
import DropZone from "../../components/UI/DropZone";

const EditProfile = () => {

	const navigate = useNavigate();
	const theme: any = useTheme();
	const matchesXS = useMediaQuery(theme.breakpoints.down("md"));
	const [ editUserTab, setEditUserTab ] = useState("one");
	const [ basicInfoTab, setBasicInfoTab ] = useState("two");
	/*
	 * const [ firstName, setFirstName ] = useState(useSelector(getFirstName) || "");
	 * const [ lastName, setLastName ] = useState(useSelector(getLastName) || "");
	 * const [ nickName, setNickName ] = useState("");
	 */
	const [ billingFirstName, setBillingFirstName ] = useState("");
	const [ billingLastName, setBillingLastName ] = useState("");
	const [ companyName, setCompanyName ] = useState("");
	const [ addressOne, setAddressOne ] = useState("");
	const [ addressTwo, setAddressTwo ] = useState("");
	const [ cityName, setCityName ] = useState("");
	const [ postalCode, setPostalCode ] = useState("");
	const [ stateName, setStateName ] = useState("");
	const [ countryName, setCountryName ] = useState("");
	const [ billingPhoneNo, setBillingPhoneNo ] = useState("");
	const [ isAllDataValid, setIsAllDataValid ] = useState(false);
	const [ errorMessage, setErrorMessage ] = useState("");
	const [ profilePhoto, setProfilePhoto ] = useState<any>(null);
	// const [ coverPhoto, setCoverPhoto ] = useState<any>(null);
	const [ isValidProfilePhoto, setIsValidProfilePhoto ] = useState(false);
	// const [ isValidCoverPhoto, setIsValidCoverPhoto ] = useState(false);
	const [ profilePhotoTab, setProfilePhotoTab ] = useState("1");
	const dispatch = useDispatch();
	const user = useSelector(getUser);
	const [ isUploadPhotoDataValid, setIsUploadPhotoDataValid ] = useState(false);

	useEffect(() => {

		// console.info(user);
		if (user) {

			// setFirstName(user?.firstName);

			// setLastName(user?.lastName);
			setBillingFirstName(user?.firstName || "");
			setBillingLastName(user?.lastName || "");
			setCompanyName(user?.userAddress?.companyName || "");
			setAddressOne(user?.userAddress?.addressOne || "");
			setAddressTwo(user?.userAddress?.addressTwo || "");
			setCityName(user?.userAddress?.cityName || "");
			setPostalCode(user?.userAddress?.postalCode || "");
			setStateName(user?.userAddress?.stateName || "");
			setCountryName(user?.userAddress?.countryName || "");
			setBillingPhoneNo(user?.userAddress?.phoneNo || "");

		}

	}, [ user ]);

	const onProfileImageDrop = useCallback((acceptedFiles: any) => {

		if (!acceptedFiles[0] || isValidProfilePhoto) {

			setIsValidProfilePhoto(true);

			return;

		}

		setIsValidProfilePhoto(false);

		setProfilePhoto(acceptedFiles[0]);

		setIsUploadPhotoDataValid(false);

	}, [ isValidProfilePhoto, profilePhoto ]);

	const handleEditProfileOptions = (event: React.SyntheticEvent, newValue: string) => {

		setEditUserTab(newValue);

	};
	const handleBasicEditProfile = (event: React.SyntheticEvent, newValue: string) => {

		setBasicInfoTab(newValue);

	};

	const handleProfilePhotoOptions = (event: React.SyntheticEvent, newValue: string) => {

		setProfilePhotoTab(newValue);

	};

	const uploadPhoto = (image: any, uploadAs: string) => {

		if (isValidProfilePhoto || profilePhoto === null || profilePhoto === "") {

			setIsUploadPhotoDataValid(true);

			return;

		}

		if (image === "" || image === null || !image || !uploadAs) {

			return;

		}

		const payLoad = {
			"fileType": `.${image?.name?.split(".").pop()}`,
			"uploadAs": uploadAs
		};

		ApiRequest("post", APIUrl.getSignedUrlForProfileIcon, payLoad).then((response: any) => {

			const uploadedImageUrl = response?.path;

			if (response?.signedUrl) {

				axios.put(response?.signedUrl, image, {
					headers: {
						"Content-Type": image?.type
					}
				}).then((response: any) => {

					if (response?.status == 200) {

						if (uploadAs === "profile") {

							setProfilePhoto(null);
							dispatch(setUser({ user: { ...user, profileIconPath: `${config?.appDomain}/${uploadedImageUrl}` } }));

						}

						toast.success("Uploaded Successfully");

					}

				}).catch((error: any) => {

					if (uploadAs === "profile") {

						setProfilePhoto(null);

					}

					toast.error("Something went wrong");

					console.error(error);

				});

			}

		}).catch((error: any) => {

			console.error(error);

		});

	};

	// const handleBasicInfoSaveChanges = () => {

	// 	if (firstName === "" || lastName === "") {

	// 		setIsAllDataValid(true);

	// 		setErrorMessage("* Fill All Required Fields");

	// 		return;

	// 	}

	// 	setIsAllDataValid(false);

	// 	setErrorMessage("");

	// };

	const handleAddressInfoSaveChanges = () => {

		console.info(billingFirstName, billingLastName, addressOne, cityName, postalCode, stateName, countryName, billingPhoneNo);

		if (billingFirstName == "" || billingLastName == "" || addressOne == "" || cityName == "" || postalCode == "" || stateName == "" || countryName == "" || billingPhoneNo == "" || !billingFirstName || !billingLastName || !addressOne || !cityName || !postalCode || !stateName || !countryName || !billingPhoneNo) {

			setIsAllDataValid(true);
			setErrorMessage("* Fill All Required Fields");

			return;

		}
		setIsAllDataValid(false);
		setErrorMessage(" ");

		const payLoad = {

			"firstName"  : billingFirstName,
			"lastName"   : billingLastName,
			"userAddress": {
				"companyName": companyName,
				"addressOne" : addressOne,
				"addressTwo" : addressTwo,
				"cityName"   : cityName,
				"postalCode" : postalCode,
				"stateName"  : stateName,
				"countryName": countryName,
				"phoneNo"    : billingPhoneNo
			}

		};
		ApiRequest("post", APIUrl.updateUserAddress, payLoad).then((response: any) => {

			const firstName = response?.data?.firstName || "";
			const lastName = response?.data?.lastName || "";

			if (response?.data) {

				dispatch(setUserAddress(response?.data));
				dispatch(setUser({ user: { ...user, firstName, lastName } }));

			}

			navigate(config?.routes?.userProfile);

		}).catch((error: any) => {

			console.error(error);

		});

	};

	const handleDeleteProfileIcon = (deleteAs: string) => {

		const payLoad = {
			"deleteUserIconType": deleteAs
		};

		ApiRequest("post", APIUrl.deleteUserProfileIcon, payLoad).then((response: any) => {

			if (response?.status == "success") {

				dispatch(setUser({ user: { ...user, profileIconPath: "" } }));

				toast.success("Deleted Successfully");

			} else {

				toast.error("Something went wrong");

			}

		});

	};

	return (
		<Container fixed className="sh-edit-user-profile-page-con">
			<Row>
				<div
					className="sh-user-edit-page-mobile-view-view-profile-button"
					style={{ color: theme?.palette?.text?.dark }}
					onClick={() => navigate(config?.routes?.userProfile)}
				>
					<div>
						<FaChevronLeft
							style={{ color: theme?.palette?.secondary?.main }}
							onClick={() => navigate(config?.routes?.userProfile)}
						/>
					</div>
					<Button
						variant="contained"
						color="secondary"
						size={matchesXS ? "small" : "medium"}
						onClick={() => navigate(config?.routes?.userProfile)}>View Profile
					</Button>
					{/* <div
						className="sh-user-edit-page-mobile-view-view-profile-button-text"
						
					>
						
					</div> */}
				</div>
			</Row>
			<Row>
				<TabContext value={editUserTab}>
					<div className="sh-user-edit-profile-tabs-container">
						<TabList
							textColor={theme?.palette?.primary?.main}
							onChange={handleEditProfileOptions}
							// sx={{ borderTop: `1px solid ${theme?.palette?.divider}` }}
							sx={{ backgroundColor: theme?.palette?.background?.default }}
							indicatorColor="secondary"
						>
							<Tab label="Edit" icon={<FaPenSquare />} iconPosition="start" value="one" />
							<Tab label="Profile Photo" icon={<FaIdCard />} iconPosition="start" value="two" />
							{/* <Tab label="Cover Photo" icon={<img src={image} />} iconPosition="start" value="three" /> */}
						</TabList>
						<div>
							<Button
								variant="contained"
								color="secondary"
								size={matchesXS ? "small" : "medium"}
								onClick={() => navigate(config?.routes?.userProfile)}
							>View Profile
							</Button>
							{/* <button style={{ color: theme?.palette?.text?.dark, backgroundColor: theme?.palette?.grey[50], width: "8em" }} className="sh-user-profile-basic-info-edit-button sh-user-profile-basic-info-edit-btn-mobile-none" onClick={() => navigate(config?.routes?.userProfile)} >View Profile</button> */}
						</div>
					</div>
					<TabPanel value="one" sx={{ backgroundColor: theme?.palette?.background?.default }}>
						<Row>
							<Typography variant="h3" sx={{ color: theme?.palette?.primary?.main }} className="sh-user-edit-profile-basic-secleted-tab-title-cover">
								{
									basicInfoTab === "one" ? "Edit \"Basic Info\" Information" : "Edit \"Your Basic\" Information"
								}
							</Typography>
						</Row>
						<Row className="sh-edit-user-profile-mobile-view-basic-info-con">
							<TabContext value={basicInfoTab}>
								<TabList
									textColor={theme?.palette?.primary?.main}
									onChange={handleBasicEditProfile}
									sx={{ backgroundColor: theme?.palette?.background?.default }}
									indicatorColor="secondary"
								>
									<Tab label="Basic Info" value="two" />
								</TabList>
								<TabPanel value="two">
									<Row>
										<Col>
											<Typography variant="subtitle1">
												First Name (Required)
											</Typography>
										</Col>
									</Row>
									<Row>

										<Form.Control
											id="outlined-basic"
											style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }}
											color="secondary"
											placeholder="Enter the First Name"
											value={billingFirstName}
											onChange={e => setBillingFirstName(e.target.value)}
											onFocus={(e: any) => {

												const focusColor: string = theme.palette.secondary.main;

												e.target.style.borderColor = focusColor;
												e.target.style.borderWidth = "1.5px";
												e.target.style.boxShadow = `rgba(0, 0, 0, 0.16) 0px 1px 0px, ${focusColor} 0px 0px 0px 0.3px`;

											}}
											onBlur={(e: any) => {

												e.target.style.borderColor = "";
												e.target.style.boxShadow = "";

											}}
											
										/>
										<style>
											{`
												#outlined-basic::placeholder {
												color: ${theme.palette.text.secondary}; /* Change placeholder color */
												opacity: 1;
												font-size: .8em;
												}
											`}
										</style>

										{/* <TextField
											fullWidth
											value={billingFirstName}
											id="outlined-basic"
											label="First Name"
											variant="outlined"
											className="sh-user-basic-edit-label-div"
											onChange={e => setBillingFirstName(e.target.value)}
										/> */}
									</Row>
									<Row>
										<Col>
											<Typography variant="subtitle1" className="sh-user-edit-profile-label-div">
												Last Name (Required)
											</Typography>
										</Col>
									</Row>
									<Row>

										<Form.Control
											id="outlined-basic"
											style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }}
											color="secondary"
											placeholder="Enter the Last name"
											value={billingLastName}
											onChange={e => setBillingLastName(e.target.value)}
											onFocus={(e: any) => {

												const focusColor: string = theme.palette.secondary.main;

												e.target.style.borderColor = focusColor;
												e.target.style.borderWidth = "1.5px";
												e.target.style.boxShadow = `rgba(0, 0, 0, 0.16) 0px 1px 0px, ${focusColor} 0px 0px 0px 0.3px`;

											}}
											onBlur={(e: any) => {

												e.target.style.borderColor = "";
												e.target.style.boxShadow = "";

											}} />

										{/* <TextField
											fullWidth
											value={billingLastName}
											id="outlined-basic"
											label="Last Name"
											variant="outlined"
											className="sh-user-basic-edit-label-div"
											onChange={e => setBillingLastName(e.target.value)}
										/> */}
									</Row>
									<Row>
										<Col>
											<Typography variant="subtitle1" className="sh-user-edit-profile-label-div">
												Company
											</Typography>
										</Col>
									</Row>
									<Row>
										<Form.Control
											id="outlined-basic"
											style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }}
											color="secondary"
											placeholder="Enter the Company Name"
											value={companyName}
											onChange={e => setCompanyName(e.target.value)}
											onFocus={(e: any) => {

												const focusColor: string = theme.palette.secondary.main;

												e.target.style.borderColor = focusColor;
												e.target.style.borderWidth = "1.5px";
												e.target.style.boxShadow = `rgba(0, 0, 0, 0.16) 0px 1px 0px, ${focusColor} 0px 0px 0px 0.3px`;

											}}
											onBlur={(e: any) => {

												e.target.style.borderColor = "";
												e.target.style.boxShadow = "";

											}} />
									</Row>
									<Row>
										<Col>
											<Typography variant="subtitle1" className="sh-user-edit-profile-label-div">
												Address 1 (Required)
											</Typography>
										</Col>
									</Row>
									<Row>
										<Form.Control
											id="outlined-basic"
											style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }}
											color="secondary"
											placeholder="Enter the Address"
											value={addressOne}
											onChange={e => setAddressOne(e.target.value)}
											onFocus={(e: any) => {

												const focusColor: string = theme.palette.secondary.main;

												e.target.style.borderColor = focusColor;
												e.target.style.borderWidth = "1.5px";
												e.target.style.boxShadow = `rgba(0, 0, 0, 0.16) 0px 1px 0px, ${focusColor} 0px 0px 0px 0.3px`;

											}}
											onBlur={(e: any) => {

												e.target.style.borderColor = "";
												e.target.style.boxShadow = "";

											}} />
									</Row>
									<Row>
										<Col>
											<Typography variant="subtitle1" className="sh-user-edit-profile-label-div">
												Address 2
											</Typography>
										</Col>
									</Row>
									<Row>
										<Form.Control
											id="outlined-basic"
											style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }}
											color="secondary"
											placeholder="Enter the Address"
											value={addressTwo}
											onChange={e => setAddressTwo(e.target.value)}
											onFocus={(e: any) => {

												const focusColor: string = theme.palette.secondary.main;

												e.target.style.borderColor = focusColor;
												e.target.style.borderWidth = "1.5px";
												e.target.style.boxShadow = `rgba(0, 0, 0, 0.16) 0px 1px 0px, ${focusColor} 0px 0px 0px 0.3px`;

											}}
											onBlur={(e: any) => {

												e.target.style.borderColor = "";
												e.target.style.boxShadow = "";

											}} />
									</Row>
									<Row>
										<Col>
											<Typography variant="subtitle1" className="sh-user-edit-profile-label-div">
												City (Required)
											</Typography>
										</Col>
									</Row>
									<Row>
										<Form.Control
											id="outlined-basic"
											style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }}
											color="secondary"
											placeholder="Enter the City Name"
											value={cityName}
											onChange={e => setCityName(e.target.value)}
											onFocus={(e: any) => {

												const focusColor: string = theme.palette.secondary.main;

												e.target.style.borderColor = focusColor;
												e.target.style.borderWidth = "1.5px";
												e.target.style.boxShadow = `rgba(0, 0, 0, 0.16) 0px 1px 0px, ${focusColor} 0px 0px 0px 0.3px`;

											}}
											onBlur={(e: any) => {

												e.target.style.borderColor = "";
												e.target.style.boxShadow = "";

											}} />

									</Row>
									<Row>
										<Col>
											<Typography variant="subtitle1" className="sh-user-edit-profile-label-div">
												Postal Code (Required)
											</Typography>
										</Col>
									</Row>
									<Row>
										<Form.Control
											id="outlined-basic"
											style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }}
											color="secondary"
											placeholder="Enter the Postal Code"
											value={postalCode}
											onChange={e => setPostalCode(e.target.value)}
											onFocus={(e: any) => {

												const focusColor: string = theme.palette.secondary.main;

												e.target.style.borderColor = focusColor;
												e.target.style.borderWidth = "1.5px";
												e.target.style.boxShadow = `rgba(0, 0, 0, 0.16) 0px 1px 0px, ${focusColor} 0px 0px 0px 0.3px`;

											}}
											onBlur={(e: any) => {

												e.target.style.borderColor = "";
												e.target.style.boxShadow = "";

											}} />

									</Row>
									<Row>
										<Col>
											<Typography variant="subtitle1" className="sh-user-edit-profile-label-div">
												State (Required)
											</Typography>
										</Col>
									</Row>
									<Row>

										<Form.Control
											id="outlined-basic"
											style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }}
											color="secondary"
											placeholder="Enter the State Name"
											value={stateName}
											onChange={e => setStateName(e.target.value)}
											onFocus={(e: any) => {

												const focusColor: string = theme.palette.secondary.main;

												e.target.style.borderColor = focusColor;
												e.target.style.borderWidth = "1.5px";
												e.target.style.boxShadow = `rgba(0, 0, 0, 0.16) 0px 1px 0px, ${focusColor} 0px 0px 0px 0.3px`;

											}}
											onBlur={(e: any) => {

												e.target.style.borderColor = "";
												e.target.style.boxShadow = "";

											}} />
									</Row>
									<Row>
										<Col>
											<Typography variant="subtitle1" className="sh-user-edit-profile-label-div">
												Country (Required)
											</Typography>
										</Col>
									</Row>
									<Row>
										<Form.Control
											id="outlined-basic"
											style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }}
											color="secondary"
											placeholder="Enter the Country Name"
											value={countryName}
											onChange={e => setCountryName(e.target.value)}
											onFocus={(e: any) => {

												const focusColor: string = theme.palette.secondary.main;

												e.target.style.borderColor = focusColor;
												e.target.style.borderWidth = "1.5px";
												e.target.style.boxShadow = `rgba(0, 0, 0, 0.16) 0px 1px 0px, ${focusColor} 0px 0px 0px 0.3px`;

											}}
											onBlur={(e: any) => {

												e.target.style.borderColor = "";
												e.target.style.boxShadow = "";

											}} />
									</Row>
									<Row>
										<Col>
											<Typography variant="subtitle1" className="sh-user-edit-profile-label-div">
												Phone Number (Required)
											</Typography>
										</Col>
									</Row>
									<Row>
										<Form.Control
											id="outlined-basic"
											style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }}
											color="secondary"
											placeholder="Enter the Phone Number"
											value={billingPhoneNo}
											onChange={e => setBillingPhoneNo(e.target.value)}
											onFocus={(e: any) => {

												const focusColor: string = theme.palette.secondary.main;

												e.target.style.borderColor = focusColor;
												e.target.style.borderWidth = "1.5px";
												e.target.style.boxShadow = `rgba(0, 0, 0, 0.16) 0px 1px 0px, ${focusColor} 0px 0px 0px 0.3px`;

											}}
											onBlur={(e: any) => {

												e.target.style.borderColor = "";
												e.target.style.boxShadow = "";

											}} />
									</Row>
									<div>
										<div>
											<Button style={{ backgroundColor: theme.palette.secondary.main }} variant="contained" onClick={handleAddressInfoSaveChanges} className="sh-user-edit-details-save-button">Save Changes</Button>
										</div>
									</div>
									{
										isAllDataValid &&
										<div>
											<Typography variant="subtitle1" sx={{ color: theme?.palette?.error?.main, marginTop: "1em" }}>
												{errorMessage}
											</Typography>
										</div>

									}
								</TabPanel>
							</TabContext>
						</Row>
					</TabPanel>
					<TabPanel value="two" sx={{ backgroundColor: theme?.palette?.background?.default }}>
						<Row>
							<Typography variant="h3" sx={{ color: theme.palette.text.primary }} className="sh-user-edit-profile-basic-secleted-tab-title">
								Change Profile Photo
							</Typography>
						</Row>
						<Row className="sh-user-profile-upload-image-con">
							<TabContext value={profilePhotoTab}>
								<div className="sh-user-edit-profile-tabs-container">

									<TabList
										textColor={theme?.palette?.primary?.main}
										onChange={handleProfilePhotoOptions}
										sx={{ backgroundColor: theme?.palette?.background?.paper }}
										indicatorColor="secondary"
									>
										<Tab value="1" label="Upload"></Tab>
										<Tab value="2" label="Delete"></Tab>
									</TabList>
								</div>
								<TabPanel value="1" sx={{ backgroundColor: theme?.palette?.background?.default }}>
									<Alert severity="info" sx={{ marginTop: "1em" }} >Your profile photo will be used on your profile and throughout the site.</Alert>
									{/* <Dropzone
										onDrop={(acceptedFiles: any) => onProfileImageDrop(acceptedFiles)}
										maxFiles={1}
										multiple={false}
										validator={(files: any) => {

											if (files?.type == "image/png" || files?.type == "image/jpeg" || files?.type == "image/jpg") {

												setIsValidProfilePhoto(false);

											} else {

												setIsValidProfilePhoto(true);

											}

											return null;

										}}
										// accept=".png, .jpg, .jpeg"
										maxSize={5242880}
										minSize={1024}
									>
										{({ getRootProps, getInputProps }: any) =>
											<div className="sh-edit-user-profile-image-upload-section" style={{ paddingTop: "15px" }}>
												<div {...getRootProps({ className: "sh-edit-profile-upload-profile-image" })} style={{ backgroundColor: theme?.palette?.background?.default, border: `3px dashed ${theme?.palette?.grey[400]}`, paddingBlock: "15px" }}>
													<input {...getInputProps({ accept: ".jpeg, .jpg, .png" })} />
													{isValidProfilePhoto ?
														<Alert severity="warning">Upload only *.png, *.jpg, *.jpeg files</Alert>
														: profilePhoto && profilePhoto.name ?
															<Typography variant="body2" style={{ color: theme.palette.text.primary, paddingBottom: "30px", marginBottom: "20px", paddingTop: "35px" }}>{profilePhoto.name}</Typography>
															:
															<Typography variant="body2" style={{ color: theme.palette.text.primary, paddingBottom: "30px", marginBottom: "20px", paddingTop: "35px" }}>Drop your image here</Typography>
													}
													<div className="sh-edit-profile-upload-image-button-style" style={{ backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, cursor: "pointer", marginBottom: "35px" }}>Select your file</div>
												</div>
											</div>
										}
									</Dropzone> */}
									<div className="sh-edit-user-profile-drop-down-component-con">
										<DropZone
											dropMedia={onProfileImageDrop}
											acceptFileType={config?.acceptedFilesImages}
											isUploadedMediaValid={profilePhoto ? true : false}
											uploadPostMediaName={profilePhoto?.name}
											accept={config?.acceptedFilesImages}
											// disabled={!profilePhoto || profilePhoto == null}

											// disabledMessage={"Please select the level"}
											message={profilePhoto ? "Click Submit to upload" : "Please upload pdf file"}
										/>
									</div>
									<Typography variant="subtitle1" className="sh-edit-user-profile-show-staus-content" sx={{ backgroundColor: theme?.palette?.background?.default, color: theme.palette.secondary.main }} style={{ background: theme?.palette?.darkBackground }} >
										{"For best results, upload an image that is 300px by 300px or larger."} <br /> {" If you'd like to delete the existing profile photo but not upload a new one, please use the delete tab."}
									</Typography>
									<div>
										<Button
											style={{ backgroundColor: isValidProfilePhoto || profilePhoto === null || profilePhoto === "" ? theme.palette.secondary.main : theme.palette.secondary.main,
												color          : isValidProfilePhoto || profilePhoto === null || profilePhoto === ""
													? theme.palette.text.primary
													: theme.palette.text.primary
												// cursor: isValidProfilePhoto || profilePhoto === null || profilePhoto === "" ? "not-allowed" : "pointer"
											 }}
											variant="contained" onClick={() => uploadPhoto(profilePhoto, "profile")}
											className={isValidProfilePhoto || profilePhoto === null || profilePhoto === ""
												? "sh-user-edit-details-save-button-disabled"
												: "sh-user-edit-details-save-button"
											  }
											  // disabled={isValidProfilePhoto || profilePhoto === null || profilePhoto === ""}

										>Upload Image</Button>
									</div>
									{
										isUploadPhotoDataValid &&
										<div style={{ fontSize: "12px", color: theme.palette.secondary.main }}>
											* Please Upload Profile Photo
										</div>
									}
								</TabPanel>
						
								<TabPanel value="2" sx={{ backgroundColor: theme?.palette?.background?.default }}>
									<Alert severity="info" sx={{ marginTop: "1em" }}>Your Cover Photo will be used to customize the header of your profile.</Alert>
									<Typography variant="subtitle1" className="sh-user-profile-delete-image-content">
								Your profile photo will be used on your profile and throughout the site.
									</Typography>
									<div style={{ paddingTop: "2em" }}>
										<Button
											variant="contained"
											style={{ backgroundColor: user?.profileIconPath === "" || user?.profileIconPath === null || user?.profileIconPath === undefined ? theme.palette.secondary.main : theme.palette.secondary.main,
												color          : user?.profileIconPath === "" || user?.profileIconPath === null || user?.profileIconPath === undefined
													? theme.palette.text.primary
													: theme.palette.text.primary
											 }}
											onClick={() => handleDeleteProfileIcon("profile")}
											// className="sh-user-edit-details-save-button"
											className={isValidProfilePhoto || profilePhoto === null || profilePhoto === ""
												? "sh-user-edit-details-save-button-disabled"
												: "sh-user-edit-details-save-button"
											  }
											disabled={user?.profileIconPath === "" || user?.profileIconPath === null || user?.profileIconPath === undefined}
										>Delete Profile Photo</Button>
									</div>
								</TabPanel>

							</TabContext>
							
						</Row>
					</TabPanel>
					{/* <TabPanel value="three" sx={{ backgroundColor: theme?.palette?.background?.default }}>
            <Row>
              <Typography variant="h3" sx={{ color: theme?.palette?.primary?.main }} className="sh-user-edit-profile-basic-secleted-tab-title-cover">
                Change Cover Photo
              </Typography>
            </Row>
            <Row>
              <Alert severity="info" sx={{ marginTop: "1em" }}>Your Cover Photo will be used to customize the header of your profile.</Alert>
              <Dropzone
                onDrop={(acceptedFiles: any) => onCoverImageDrop(acceptedFiles)}
                maxFiles={1}
                multiple={false}
                maxSize={5242}
                minSize={1024}
              >
                {({ getRootProps, getInputProps }: any) =>
                  <div className="sh-edit-user-profile-image-upload-section">
                    <div {...getRootProps({ className: "sh-edit-profile-upload-profile-image" })} style={{ backgroundColor: theme?.palette?.text?.hint, border: `3px dashed ${theme?.palette?.grey[400]}` }}>
                      <input {...getInputProps({ accept: ".jpeg, .jpg, .png" })} />
                      {isValidCoverPhoto ?
                        <Alert severity="warning">Upload only *.png, *.jpg, *.jpeg files</Alert>
                        : coverPhoto && coverPhoto.name ?
                          <Typography variant="body2">{coverPhoto.name}</Typography>
                          :
                          <Typography variant="body2">Drop your image here</Typography>
                      }
                      <div className="sh-edit-profile-upload-image-button-style" style={{ backgroundColor: theme?.palette?.primary?.main, color: theme?.palette?.primary?.light }}>Select your file</div>
                    </div>
                  </div>
                }
              </Dropzone>
              <Typography variant="subtitle1" className="sh-edit-user-profile-show-staus-content" sx={{ backgroundColor: theme?.palette?.warning?.light, color: theme?.palette?.orange?.main }}>
                For best results, upload an image that is 1950px by 450px or larger.
              </Typography>
                <div>
                  <Button variant="contained" onClick={() => uploadPhoto(coverPhoto, "cover")} className="sh-user-edit-details-save-button">Upload Image</Button>
                </div>
            </Row>
          </TabPanel> */}
				</TabContext>
			</Row>
		</Container>
	);

};

export default EditProfile;