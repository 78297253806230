import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import config from "../../config/appConfig";
import { auth } from "../../firebase";

const AuthMode = ({ children }: any) => {

	const [ isAuthenticated, setIsAuthenticated ] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {

		const unsubscribe = onAuthStateChanged(auth, user => {

			if (user) {

				setIsAuthenticated(true);

			} else {

				setIsAuthenticated(false);
				navigate(config.routes.login, { replace: true });

			}

		});

		return () => unsubscribe();

	}, [ navigate ]);


	return isAuthenticated ? children : null;

};

export default AuthMode;