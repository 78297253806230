import shaka from "shaka-player/dist/shaka-player.ui.js";
import React, { useState, useRef, useEffect, useImperativeHandle, ForwardedRef } from "react";
import "shaka-player/dist/controls.css";

interface ShakaPlayerProps {
  src: string;
  config?: shaka.extern.PlayerConfiguration;
  chromeless?: boolean;
  className?: string;
  player?: shaka.Player;
  setPlayer?: (player: shaka.Player) => void;
	watchDuration?: number;
	videoRef: any;
	updateUserProgressAndNavigate?: (duration: number) => void;
  [key: string]: any;
}

const ShakaPlayer = (
	{ src, config, chromeless, className, player, setPlayer, watchDuration, videoRef, updateUserProgressAndNavigate, ...rest }: ShakaPlayerProps,
	ref: ForwardedRef<any>
) => {

	const uiContainerRef: any = useRef<HTMLDivElement>(null);
	// const videoRef: any = useRef<HTMLVideoElement>(null);

	const [ ui, setUi ] = useState<shaka.ui.Overlay | null>(null);
	const [ currentTime, setCurrentTime ] = useState(0);

	useEffect(() => {

		const onErrorEvent = (event: Event) => {

			console.error("Error code", (event as any).detail.code, "object", (event as any).detail);
		
		};

		const playerInstance = new shaka.Player(videoRef.current);
		setPlayer?.(playerInstance);

		playerInstance.addEventListener("error", onErrorEvent);

		let uiInstance: shaka.ui.Overlay | null = null;
		if (!chromeless) {

			uiInstance = new shaka.ui.Overlay(playerInstance, uiContainerRef?.current, videoRef.current);
			uiInstance.getControls();

			const uiConfig = {
				overflowMenuButtons: [
					"captions", "quality", "language", "picture_in_picture", "playback_rate"
				],
				controlPanelElements: [
					"mute", "volume", "time_and_duration", "spacer", "fullscreen", "picture_in_picture", "overflow_menu"
				],
				seekBarColors: {
					base    : "rgba(255, 255, 255, 0.3)",
					buffered: "rgba(255, 255, 255, 0.54)",
					played  : "rgb(255, 255, 255)"
				},
				addSeekBar      : true,
				addBigPlayButton: true
			};

			uiInstance.configure(uiConfig);

			setUi(uiInstance);
		
		}

		return () => {

			playerInstance.destroy();
			uiInstance?.destroy();
		
		};
	
	}, []);

	useEffect(() => {

		if (player && config) {

			player.configure(config);
		
		}
	
	}, [ player, config ]);

	useEffect(() => {

		if (player && src) {

			player.load(src);
		
		}
	
	}, [ player, src ]);

	useEffect(() => {

		if (player && src) {

			player.load(src).then(() => {

				// Set the start time if watchDuration is available
				if (watchDuration) {

					if (videoRef.current && videoRef.current.duration && videoRef.current.duration !== watchDuration) {

						if (Math.floor(videoRef.current.duration) !== Math.floor(watchDuration)) {
						
							videoRef.current.currentTime = watchDuration;

						}
					
					}

				
				}
			
			});
		
		}
	
	}, [
		player, src, watchDuration
	]);

	useEffect(() => {

		if (videoRef.current) {

			// Add event listener to update current time
			videoRef.current.addEventListener("timeupdate", () => {

				if (videoRef.current && !videoRef.current.paused && !videoRef.current.ended && videoRef.current.readyState > 2) {
					
					setCurrentTime(videoRef.current.currentTime);
					
				} else {
					
					if (videoRef.current && videoRef?.current?.ended) {
	
						if (videoRef.current.currentTime === videoRef.current.duration) {
	
							updateUserProgressAndNavigate?.(videoRef.current.duration);
						
						}

					}

				}
			
			});
		
		}
	
	}, []);

	useImperativeHandle(ref, () => ({
		get player() {

			return player;
		
		},
		get ui() {

			return ui;
		
		},
		get videoElement() {

			return videoRef.current;
		
		},

		getCurrentTime() {
		
			// Added method to get current time

			return currentTime;
		
		}

	}), [
		player, ui, currentTime
	]);

	return (
		<div ref={uiContainerRef} className={className}>
			<video
				ref={videoRef}
				style={{
					maxWidth: "100%",
					width   : "100%"
				}}
				{...rest}
			/>
		</div>
	);

};

export default React.forwardRef(ShakaPlayer);
