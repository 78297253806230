import React, { useEffect, useState } from "react";
import { Typography, Button, Grid, Rating
	// CardMedia,
} from "@mui/material";
import { useTheme, useMediaQuery } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { Tab, Tabs } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useNavigate, useLocation } from "react-router-dom";

import config from "../../config/appConfig";
import { APIUrl } from "../../api/endPoints";

import ApiRequest from "../../api/ApiRequest";
// import { useDispatch, useSelector } from "../../store";
import { useSelector } from "../../store";
// import { setUser } from "../../store/slices/userSlice";
import CustomLoader from "../../components/UI/cards/CustomLoader";
import MainButton from "../../components/UI/extend/MainButton";
// import SubCard from "../../components/UI/cards/SubCard";
import { auth } from "../../firebase";

const ListCourse = () => {

	const navigate = useNavigate();
	const user = auth.currentUser;
	const theme: any = useTheme();
	const matchesXS = useMediaQuery(theme.breakpoints.down("md"));
	const location = useLocation();
	const state = location.state;
	const skillLevel = state?.skillLevel;

	// const dispatch = useDispatch();
	const { id } = useSelector((state: any) => state.user);
	const [ isUserDataLoading, setIsUserDataLoading ] = useState(true);
	const [ courseData, setCourseData ] = useState([]);
	const [ selectedCourse, setSelectedCourse ] = useState<any>("Self Placed Modules");
	const [ moduleData, setModuleData ] = useState<any>([]);
	const [ copyModuleData, setCopyModuleData ] = useState<any>([]);
	const [ isModuleDataLoading, setIsModuleDataLoading ] = useState(true);
	const [ filterTab, setFilterTab ] = useState("all");
	const [ hoveredCardIndex, setHoveredCardIndex ] = useState(-1);

	console.info(moduleData);

	let isDataFetched = false;

	useEffect(() => {

		if (skillLevel && skillLevel == "") navigate(config.routes.userIntro);

		if (isDataFetched && id !== "") return;

		isDataFetched = true;
		setIsUserDataLoading(true);

		ApiRequest("get", APIUrl.listAllCourse).then((response: any) => {

			setCourseData(response);
			setIsUserDataLoading(false);

			let courseName = "";

			if (skillLevel) {

				if (skillLevel == config.skillLevel.beginner || skillLevel == config.skillLevel.firstTimer) {
	
					courseName = config.courseList.selfPaced;
	
				} else {
	
					courseName = config.courseList.songsAndSkill;
	
				}

			}

			courseName = courseName ? courseName : config.courseList.selfPaced;
			setSelectedCourse(courseName);
			const data: any = response.find((course: any) => course.courseName == courseName);


			if (data) {

				fetchModules(data._id);

			}

		}).catch((error: any) => {

			console.error(error);
			setIsUserDataLoading(false);

		});


	}, [ user ]);

	const fetchModules = (courseId: any) => {

		if (selectedCourse !== "") {

			setIsModuleDataLoading(false);

			const payload = {
				courseId: courseId
			};

			ApiRequest("post", APIUrl.listAllModuleByCourseId, payload).then((response: any) => {

				setModuleData(response);

				if (filterTab == config?.enrolled) {

					const enrolledData = response.filter((course: any) => {

						return course.unlocked;

					});

					setCopyModuleData(enrolledData);

				} else {

					setCopyModuleData(response);

				}

				setIsModuleDataLoading(false);

			}).catch((error: any) => {

				setModuleData([]);
				setCopyModuleData([]);
				setIsModuleDataLoading(false);
				setIsUserDataLoading(false);
				console.error(error);


			});

		}

	};

	const handleFilterChange = ( event: any, newValue: any ) => {

		setFilterTab(newValue);

		if (newValue === config?.enrolled) {

			const enrolledData = moduleData.filter((course: any) => {

				return course.unlocked;

			});

			setCopyModuleData(enrolledData);

		} else {

			setCopyModuleData(moduleData);

		}

	};

	return (
		<>{
			isUserDataLoading ? <CustomLoader /> :
				<div >
				
					<Row
						className="course-list-header-con"
						style={{
							textAlign      : "center",
							backgroundColor: theme.palette.background.paper,
							marginBottom 	 : "2rem"
						}}>
						<Col className="course-list-header-col-con" md={6} sm={8} xs={12}>
							<div className="course-list-heading-tab-col-content-con">
								<div>
									{
										state?.fromIntro == true ?
											<Typography variant="h2">
											We recommend you kickstart your violin journey with our&nbsp;
												<span style={{ color: theme?.palette?.secondary?.main }}>
													{
														skillLevel == config.skillLevel.beginner || skillLevel == config.skillLevel.firstTimer ? "Self-Paced Modules!" : "Songs and Skills!"
													}
												</span>
											</Typography> :
											<Typography variant="h2">
											Continue your violin Journey...&nbsp;
											</Typography>
									}
								</div>
							</div>
							<div className="course-list-heading-tab-col-content-con">

								{courseData.map((course: any, index: any) => {

									return <>
										<div
											key={index}
										>
											<MainButton
												type="scale"
												direction="bottom"
												style={{ position: "relative", cursor: "pointer" }}
												offset={matchesXS ? 0 : 20}
												scale={1.04}>
												<Button
													style={{ fontWeight: 500, fontSize: "0.8rem" }}
													variant={selectedCourse == course.courseName ? "contained" : "outlined"}
													sx={ selectedCourse == course.courseName ? { backgroundColor: theme.palette.secondary.main, color: theme.palette.grey[100], "&:hover": { backgroundColor: theme.palette.secondary.main, color: theme.palette.grey[100] }, borderRadius: "1.5em"

													} : { backgroundColor: theme?.palette?.background.default, color: theme.palette.grey[100], borderColor: theme.palette.grey[100], "&:hover": { backgroundColor: theme.palette.secondary.main, color: theme.palette.grey[100], borderColor: theme.palette.secondary.main }, borderRadius: "1.5em" }}
													onClick={() => {

														if (selectedCourse !== course.courseName) {

															setIsModuleDataLoading(true);
															setSelectedCourse(course.courseName);
															fetchModules(course._id);

														}

														// navigate(config.routes.modules, { state: { id: course._id, name: course.courseName } });

													}} >
													{course.courseName}
												</Button>
											</MainButton>
										</div>
									</>;

								}

								)}

							</div>
						</Col>
						{/* <Col className="course-list-header-col-con" md={6} sm={6} xs={6}></Col> */}
					</Row>
					<div >
						<Row
							style={{
								marginBottom: "3em",
								borderRadius: "1em"
							}}
						>
							<Col style={{ width: "100%" }} md={12} sm={12} xs={12}>
								<Tabs value={filterTab} onChange={handleFilterChange}
									className="sh-list-modules-filter-tab-tablist-con"
									indicatorColor="secondary"
									textColor="primary"
								>
									<Tab
										label="All"
										value={config?.all}
									/>
									<Tab
										label="Enrolled Modules"
										value={config?.enrolled}
									/>
								</Tabs>
							</Col>
						</Row>
						{
							isModuleDataLoading ? <CustomLoader /> : <Row>
								<Col style={{ width: "100%" }} md={12} sm={12} xs={12}>
									{copyModuleData.length > 0 &&
								<Grid container spacing={2}>

									{copyModuleData && copyModuleData?.length > 0 && copyModuleData.map((course: any, index: any) => {

										return <>
											<Grid item
												xs={12}
												sm={6}
												md={4}
												lg={3}
												xl={3}
												key={index}
											>
												<div
													onMouseEnter={() => setHoveredCardIndex(index)}
													onMouseLeave={() => setHoveredCardIndex(-1)}
												>
													<MainButton
														type="scale"
														direction="bottom"
														style={{ position: "relative", cursor: "pointer", marginRight: "1em" }}
														offset={matchesXS ? 0 : 20}
														scale={1.04}>
														
														<div
															style={{
																backgroundImage   : `url(${config.appDomain}/${course.moduleIcon})`,
																backgroundSize    : "cover",
																backgroundPosition: "center",
																minHeight         : "360px",
																width             : "100%",
																borderRadius      : "1em",
																border            : "1px solid #000000",
																display           : "flex",
																alignItems        : "flex-end",
																position          : "relative"
															}}
															onClick={() => {

																
																const newCourseData = course;
																
																newCourseData["courseType"] = state?.name;
																
																if (!newCourseData?.unlocked && course.level > 0) {
																	
																	navigate(config.routes.moduleIntro, { state: { moduleData: JSON.stringify(newCourseData) } });
																	
																} else if (newCourseData?.unlocked && course.level > 0) {
				
																	navigate(config?.routes?.video, { state: { moduleData: newCourseData } });
				
																}
				
															}}
														>
															{
																hoveredCardIndex === index && (!course.unlocked || course.level == 0) && <div
																	className="sh-list-modules-over-lay"
																	onClick={() => {

																		const newCourseData = course;
		
																		newCourseData["courseType"] = state?.name;
		
																		if (!newCourseData?.unlocked && course.level > 0) {
		
																			navigate(config.routes.moduleIntro, { state: { moduleData: JSON.stringify(newCourseData) } });
		
																		}
		
																	}}
																>
																	<Typography style={{ fontSize: "1.2em" }} variant="body1">{ course.level == 0 ? "No Content" : "Unlock Now" }</Typography>
																</div>
															}
															<div
																style={{ position: "absolute", top: "2%", left: "86%" }}
															>
																{
																	!course.unlocked && <LockOutlinedIcon />
																}
															</div>
															<div className="course-module-list-div-con">
																<Typography className="course-desc-con" sx={{
																	// color: theme?.palette?.text?.primary
																	color: theme?.palette?.text?.primary
																}} variant="h5">{course.moduleName}
																</Typography>
																<div className="sh-list-modules-lock-icon-videos-text-con">
																	<div>
																		<Typography className="course-duration-con"
																			style={{ color: theme?.palette?.text.secondary }}
																			variant="body1">
																			<span style={{ color: theme?.palette?.text.primary }}
																			>{course?.level ? course?.level : 0}</span > Videos</Typography>
																	</div>
																	<div>
																		<Rating
																			readOnly
																			defaultValue={course?.averageRating}
																			precision={0.5}
																		/>
																	</div>
																</div>

															</div>
														
														</div>
													
													</MainButton>
												</div>
											</Grid>
										</>;

									}

									)}
								</Grid>
									}
								</Col>
							</Row>
						}
						
					</div>
				</div >
		}
		</>

	);

};

export default ListCourse;
