import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import VideoJS from "./VideoPlayer";

// navigate imports
import { useLocation, useNavigate } from "react-router-dom";
// import { Container } from "@mui/system";

// icons
import { FaCheck, FaPlayCircle } from "react-icons/fa";
import { IoDocumentAttachOutline } from "react-icons/io5";
import { FaAngleLeft } from "react-icons/fa6";

// mui imports
import { Typography, Button } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";

import { useTheme, useMediaQuery } from "@mui/material";
import MainButton from "../../components/UI/extend/MainButton";
// import Tooltip from "@mui/material/Tooltip";
import TooltipComponent from "../../components/UI/Tooltip";

// rsuite imports
import { Accordion, Col, Row } from "rsuite";

// other imports
import config from "../../config/appConfig";
import ApiRequest from "../../api/ApiRequest";
import { APIUrl } from "../../api/endPoints";
import CustomLoader from "../../components/UI/cards/CustomLoader";
import { useSelector } from "../../store";
import _ from "lodash";
import { setUser, setUserProgressData } from "../../store/slices/userSlice";
import UserComments from "./userComments";
import RatingComponent from "./Rating";
import ModalComponent from "../../components/modal";

const Video = () => {

	const location = useLocation();
	const dispatch = useDispatch();
	const theme: any = useTheme();
	const navigate = useNavigate();
	const matchesXS = useMediaQuery(theme.breakpoints.down("md"));
	const state = location.state?.moduleData;
	const { userProgressData } = useSelector((state: any) => state.user);
	const [ expanded, setExpanded ] = useState<any>(0);
	const [ activeTab, setActiveTab ] = useState("1");
	const [ levelData, setLevelData ] = useState<any>([]);
	const [ currentLesson, setCurrentLesson ] = useState<any>({});
	const [ isLoading, setIsLoading ] = useState(true);
	const [ currentLessonId, setCurrentLessonId ] = useState("");
	const [ currentLevelId, setCurrentLevelId ] = useState("");
	const [ videoData, setVideoData ] = useState<any>({});
	const [ isVideoDataLoading, setIsVideoDataLoading ] = useState(true);
	const [ player, setPlayer ] = useState<any>(null);
	const [ isFeedBackModalOpen, setIsFeedBackModalOpen ] = useState(false);

	const [ value, setValue ] = useState<number | null>(null);
	const [ feedback, setFeedback ] = useState("");
	const [ feedbackId, setFeedbackId ] = useState("");

	const shakaRef: any = useRef(null);
	const videoRef: any = useRef(null);

	useEffect(() => {

		setIsLoading(true);
		
		if (state) {
			
			if (!state?.unlocked) navigate(config?.routes?.course);

			setIsVideoDataLoading(true);

			if (!Object.keys(userProgressData).length) {

				ApiRequest("get", APIUrl.getUserInfo).then((response: any) => {
	
					dispatch(setUser({ user: response }));

					dispatch(setUserProgressData(response.userProgressData));
	
					// userProgressData = response.userProgressData;

					getUserProgressData(response?.userProgressData);
	
				}).catch((error: any) => {
	
					navigate(config.routes.landingHome);

					console.error(error);
				
	
				});
	
			} else {

				getUserProgressData(userProgressData);

			}

			// }

		}


		return () => {
			
			updateUserProgress(currentLesson?.lessonId, videoRef.current?.currentTime, currentLesson);

		};

	}, []);

	const getUserProgressData = async (userProgressData: any) => {

		ApiRequest("post", APIUrl.listAllLevelAndLessonByModule, { courseId: state?.courseId, moduleId: state?._id }).then(async (response: any) => {
				
			let mergedData: any = response;
			const feedbackData: any = mergedData?.[0]?.moduleFeedback;

			if (Object.keys(feedbackData).length !== 0 && feedbackData.constructor === Object) {
				
				setValue(feedbackData?.rating || 2);
				setFeedback(feedbackData?.feedback || "");
				setFeedbackId(feedbackData?._id || "");

			}

			// if (Object.keys(userProgressData).length !== 0 && userProgressData.constructor === Object) {

			if (Object.keys(userProgressData).length !== 0) {

				if (userProgressData?.courses && userProgressData.courses?.length > 0) {

					const currentCourseIndex = _.findIndex(userProgressData?.courses, (o: any) => o.courseId === state?.courseId);
					const courseData = userProgressData?.courses[currentCourseIndex];
					const currentModuleId = courseData?.currentModule;
					const currentLessonId = courseData?.currentLesson;
					const currentLevelId = courseData?.currentLevel;
					let moduleIndex = 0;

					if (currentModuleId == state?._id) {

						moduleIndex = _.findIndex(courseData?.moduleProgress, (o: any) => o.moduleId === currentModuleId);

					} else {

						moduleIndex = _.findIndex(courseData?.moduleProgress, (o: any) => o.moduleId === state?._id);

					}

					const moduleData = courseData?.moduleProgress[moduleIndex];

					mergedData = mergeProgressIntoModuleData(response, moduleData);

					handleUpdateCurrentLesson(mergedData, currentLessonId, currentLevelId);

				}

			}

			if (mergedData && mergedData.length > 0) {

				setCurrentLesson(mergedData[0]?.lessons[0]);
				setCurrentLessonId(mergedData[0]?.lessons[0]?.lessonId);
				setCurrentLevelId(mergedData[0]?.levelId);

			} else {
				
				setCurrentLesson(null);
				setCurrentLessonId("");
				setCurrentLevelId("");

			}

			setIsLoading(false);
			setIsVideoDataLoading(false);
			setLevelData(mergedData);

		}).catch((error: any) => {

			setIsLoading(false);
			setIsVideoDataLoading(false);
			console.error("Error", error);

		});

	};

	useEffect(() => {

		const lessonData = currentLesson;

		const handleBeforeUnload = async () => {

			const currentTime = await videoRef.current?.currentTime;
			await updateUserProgress(currentLesson?.lessonId, currentTime, lessonData);

		};

		window.addEventListener("beforeunload", handleBeforeUnload);

		return () => {

			window.removeEventListener("beforeunload", handleBeforeUnload);

		};

	}, []);

	const handleUpdateCurrentLesson = (mergedData: any, currentLessonId: any, currentLevelId: any) => {

		let currentLesson: any = {};
		let currentLevel: any = {};

		if (mergedData && mergedData.length > 0) {

			mergedData?.map((level: any, index: any) => {

				if (level?.levelId === currentLevelId) {

					level?.lessons.map((lesson: any) => {

						if (lesson?.lessonId === currentLessonId) {

							setExpanded(index);

							currentLesson = lesson;
							currentLevel = level;

						} else {

							currentLesson = mergedData[0]?.lessons[0];
							currentLevel = mergedData[0];
							setExpanded(0);

						}


					});

				}

			});

			if (Object.keys(currentLesson).length !== 0 && currentLesson.constructor === Object) {

				setCurrentLesson(currentLesson);
				setCurrentLessonId(currentLesson.lessonId);
				setCurrentLevelId(currentLevel.levelId);

			} else {

				setCurrentLesson(mergedData[0]?.lessons[0]);
				setCurrentLessonId(mergedData[0]?.lessons[0]?.lessonId);
				setCurrentLevelId(mergedData[0]?.levelId);

			}

		} else {

			setCurrentLesson(mergedData[0]?.lessons[0]);
			setCurrentLessonId(mergedData[0]?.lessons[0]?.lessonId);
			setCurrentLevelId(mergedData[0]?.levelId);

		}


	};

	useEffect(() => {

		const levelId = currentLesson?.levelId;

		if (levelId) {

			const levelIndex = _.findIndex(levelData, (o: any) => o.levelId === levelId);

			if (levelIndex !== -1) {

				setExpanded(levelIndex);

			}

		}

	}, [ currentLesson ]);

	const mergeProgressIntoModuleData = (moduleData: any, progressData: any) => {

		if (!progressData) return moduleData;

		const progressMap = _.flatMap(progressData.levels, level => level.lessons.map((lesson: any) => ({
			...lesson,
			levelCompletionStatus : level.levelCompletionStatus,
			moduleCompletionStatus: progressData.moduleCompletionStatus,
			levelProgress         : progressData.levelProgress,
			lessonsProgress       : level.lessonsProgress
		}))).reduce((acc, lesson) => {

			acc[lesson.lessonId] = lesson;
				
			return acc;
			
		}, {});

		moduleData.map((level: any) => {

			level.lessons = level.lessons.map((lesson: any) => {

				const progress = progressMap[lesson.lessonId];

				if (progress) {

					lesson.durationWatched = progress?.durationWatched || 0;
					lesson.completionStatus = progress?.completionStatus;
					lesson.currentTimeStamp = progress?.currentTimeStamp;
					lesson.videoDuration = progress?.videoDuration;
					level.levelCompletionStatus = progress?.levelCompletionStatus;
					level.levelProgress = progress?.levelProgress;
					level.moduleCompletionStatus = progress?.moduleCompletionStatus;
					level.lessonsProgress = progress?.lessonsProgress;


				}

				return lesson;

			});

			return level;

		});

		return moduleData;

	};

	const handleActiveTabChange = (event: React.SyntheticEvent, newValue: string) => {

		setActiveTab(newValue);

	};

	const updateUserProgress = (lessonId: string, watchedDuration: any, lessonData: any) => {

		return new Promise((resolve, reject) => {

			try {

				if (lessonId == lessonData?.lessonId && lessonData?.lessonContent == videoData?.guid) {

					const completionStatus = lessonData?.completionStatus == "completed" ? lessonData?.completionStatus : Math.floor(watchedDuration) === Math.floor(videoRef?.current?.duration) ? "completed" : "in-progress";
					const payLoad = {
						"courseId"        : lessonData?.courseId,
						"moduleId"        : lessonData?.moduleId,
						"levelId"         : lessonData?.levelId,
						"videoDuration"   : videoRef?.current?.duration ? Math.floor(videoRef?.current?.duration) : 0,
						"watchedDuration" : watchedDuration,
						"lessonId"        : lessonId,
						"completionStatus": completionStatus
					};

					if (videoRef?.current?.duration) {

						ApiRequest("post", APIUrl.updateUserProgress, payLoad).then((response: any) => {
		
							dispatch(setUserProgressData(response?.updatedDoc || {}));
	
							const userProgressData = response?.updatedDoc;
	
							const currentCourseIndex = _.findIndex(userProgressData?.courses, (o: any) => o.courseId === state?.courseId);
							const courseData = userProgressData?.courses[currentCourseIndex];
							const currentModuleId = courseData?.currentModule;
							let moduleIndex = 0;
	
							if (currentModuleId == state?._id) {
	
								moduleIndex = _.findIndex(courseData?.moduleProgress, (o: any) => o.moduleId === currentModuleId);
	
							} else {
	
								moduleIndex = _.findIndex(courseData?.moduleProgress, (o: any) => o.moduleId === state?._id);
	
							}
	
							const moduleData = courseData?.moduleProgress[moduleIndex];
							const mergedData = mergeProgressIntoModuleData(levelData, moduleData);
	
							handleUpdateCurrentLesson(mergedData, currentLessonId, currentLevelId);
	
							resolve(response);
	
						}).catch((error: any) => {
	
							console.error("Error", error);
	
							reject(error);
	
						});

					}

				}

			} catch (error) {

				console.error("Error", error);

				reject(error);

			}

		});

	};

	const handleSubmitFeedback = (event: any) => {

		const payload: any = {
			"courseId": state?.courseId,
			"moduleId": state?._id,
			"levelId" : currentLevelId,
			"lessonId": currentLesson?.lessonId,
			"rating"  : value,
			"feedback": feedback
		};

		if (feedbackId) {

			payload["feedbackId"] = feedbackId;

		}

		ApiRequest("post", APIUrl.updateUserFeedBack, payload).then(() => {

			setIsFeedBackModalOpen(false);
			event.preventDefault();

		}).catch((error: any) => {

			console.error(error);

		});

	};

	const handleDownloadReferenceDoc = (lesson: any) => {

		const payload = {
			"lessonData": {
				"lessonId": lesson?.lessonId
			}
		};

		ApiRequest("post", APIUrl.getSignedUrlDownloadRefDoc, payload).then((response: any) => {

			if (response?.url) {

				const link = document.createElement("a");
				link.href = response?.url;
				link.target = "_blank";
				link.setAttribute("download", lesson?.referenceDoc);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);

			}

		}).catch((error: any) => {

			console.error("Error", error);

		});

	};

	const formatDuration = (duration: number): string => {

		const minutes = Math.floor(duration / 60);
		const seconds = Math.floor(duration % 60);
		
		return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
	
	};

	return <>{isLoading ? <CustomLoader /> : <>
		<>
			<div >
				<Row>
					<Col className="swara-video-page-titile-con" md={12} sm={12} xs={12}>
						<div>
							<TooltipComponent text={"Back to Course"} placement="right">
								<div style={{ width: "fit-content" }}>
									<MainButton
										type="scale"
										direction="bottom"
										style={{ position: "relative" }}
										offset={matchesXS ? 0 : 20}
										scale={1.1}>
										<span
											className="sh-back-btn-component-span"
											onClick={async () => {

												// const currentTime = await getCurrentTimeOfVideo();
												let currentTime = 0;

												if (videoRef.current && !videoRef.current.paused && !videoRef.current.ended && videoRef.current.readyState > 2) {

													currentTime = videoRef.current.currentTime;

													try {
														
														await updateUserProgress(currentLesson?.lessonId, currentTime, currentLesson);
														navigate(config.routes.course);
	
													} catch (error) {
	
														navigate(config.routes.course);
														console.error("Error", error);
	
													}

												} else {
													
													navigate(config.routes.course);

												}

											}}
										>
											<FaAngleLeft
												size={20}
												style={{ color: theme?.palette?.primary?.main }}
											/>
										</span>
									</MainButton>
								</div>
							</TooltipComponent>

						</div>
						<Typography variant="h4">
							{currentLesson?.lessonName || ""}
						</Typography>
					</Col>
				</Row>
				<Row
					gutter={16}
				>
					<Col
						xxl={18}
						xl={18}
						lg={18}
						md={18}
						sm={24}
						xs={24}
						
					>
						<Row>
							<VideoJS
								videoId={currentLesson?.lessonContent || ""}
								currentLessonId={currentLessonId}
								setCurrentLessonId={setCurrentLessonId}
								currentLevelId={currentLevelId}
								setCurrentLevelId={setCurrentLevelId}
								levelData={levelData}
								setVideoData={setVideoData}
								currentLesson={currentLesson}
								setCurrentLesson={setCurrentLesson}
								updateUserProgress={updateUserProgress}
								isVideoDataLoading={isVideoDataLoading}
								setIsVideoDataLoading={setIsVideoDataLoading}
								player={player}
								setPlayer={setPlayer}
								shakaRef={shakaRef}
								videoRef={videoRef}
								setExpanded={setExpanded}
							/>
						</Row>

					</Col>
					<Col
						xxl={6}
						xl={6}
						lg={6}
						md={6}
						sm={24}
						xs={24}
					>
						<Accordion
							activeKey={expanded} bordered onSelect={async (s: any) => {
							
								setExpanded(s);

							}} style={{
								backgroundColor: theme?.palette?.background?.paper,
								borderColor    : theme?.palette?.background?.paper

							 }}>
							{
								levelData && levelData?.length > 0 && levelData.map((level: any, index: number) => {

									return (

										<Accordion.Panel style={{
											backgroundColor: theme?.palette?.background?.paper,
											color          : currentLevelId === level?.levelId ? theme?.palette?.secondary?.main : theme?.palette?.text.primary
										}} header={level?.levelName || ""} onClick={() => {

										}} eventKey={index} key={index}>
											{
												level?.lessons && level?.lessons?.length > 0 && level?.lessons.map((lesson: any, index: number) => {

													const isCompleted = lesson?.completionStatus === "completed" ? true : false;

													return (
														<div
															className="soul-video-lesson-list-con"
															style={{ color: isCompleted ? theme?.palette?.secondary?.main : "black" }}
															onClick={async () => {

																if (currentLesson?.lessonId == lesson?.lessonId) return;

																const currentTime = await videoRef.current?.currentTime;
																await updateUserProgress(currentLesson?.lessonId, currentTime, currentLesson);
															
																setCurrentLesson(lesson);
																setCurrentLessonId(lesson?.lessonId);
																setCurrentLevelId(level?.levelId);

															}} key={index}>
															<Typography sx={{
																color: isCompleted || currentLessonId == lesson.lessonId ? theme?.palette?.secondary?.main : theme?.palette?.text.primary
															}} variant="h6">
																
																<div className="ss-video-file-name-with-duration">
																	<div
																		className="ss-video-file-name"
																	><span>
																			{ isCompleted ? <FaCheck color={theme?.palette?.secondary?.main} /> :
																				<FaPlayCircle color={theme?.palette?.grey[500]} />}
																		</span>{`${lesson?.lessonName}`}</div>
																	<div
																		style={{
																			color: theme?.palette?.grey[500]
																		}}
																		className="ss-video-file-duration"
																	>
																		{
																			formatDuration(lesson?.videoDuration || 0)
																		}
																	</div>
																</div>
															</Typography>
														</div>
													);

												})
											}
										</Accordion.Panel>

									);

								})

							}
						</Accordion>
					</Col>
				</Row>
				<Row>
					<TabContext
						value={activeTab}>
						<TabList
							indicatorColor="secondary"
							textColor="secondary"
							onChange={handleActiveTabChange} aria-label="lab API tabs example">
							<Tab
								color="secondary"
								/*
								 * sx={{
								 * 	"&.Mui-selected": {
								 * 		color       : theme?.palette?.secondary?.main,
								 * 		borderBottom: `2px solid ${theme?.palette?.secondary?.main}`
								 * 	}
								 * }}
								 */
								label="Overview" value="1" />
							<Tab style={{ display: "none" }} label="Q&A" value="2" />
							<Tab label="Reference Document" value="3" />
						</TabList>
						<TabPanel value="1">
							<Typography variant="h4">Module: {currentLesson?.moduleName}</Typography>
							<Typography sx={{
								padding: "1em 0"
							}} variant="body1">{state.moduleDesc}</Typography>
							<Typography variant="h5">Title: {currentLesson?.lessonName}</Typography>
							<Typography sx={{
								padding: "1em 0"
							}} variant="body1">{currentLesson?.lessonDesc}</Typography>
							<div>
										Module Rating: <span style={{ cursor: "pointer" }}>
									<RatingComponent
										setIsFeedBackModalOpen={setIsFeedBackModalOpen}
										isToOpen={true}
										value={value}
										setValue={setValue}
										feedback={feedback}
										setFeedback={setFeedback}
									/>
								</span>
							</div>
						</TabPanel>
						<TabPanel style={{ display: "none" }} value="2">
							<UserComments lessonId={currentLesson?.lessonId} courseId={state?.courseId} moduleId={state?._id} levelId={currentLevelId} />
						</TabPanel>
						{
							currentLesson?.referenceDoc ? <TabPanel value="3">
								<Typography style={{
									marginBottom: "1em"
								}} variant="h5">Reference Document</Typography>
								<div
									onClick={() => handleDownloadReferenceDoc(currentLesson)}
									className="sh-video-player-pdf-download-btn"
								>
									<div
										style={{
											backgroundColor: theme?.palette?.secondary?.light,
											padding        : "0.7em",
											borderRadius   : "50%"
										}}
									>
										<IoDocumentAttachOutline
												 size={35}
												 color={theme?.palette?.secondary?.main}
												 />
									</div>
									<div>
										{currentLesson?.referenceDoc}
									</div>
								</div>
							</TabPanel> : <TabPanel value="3">
								<Typography variant="h6">No Reference Document Available</Typography>
							</TabPanel>
						}
								
					</TabContext>
				</Row>
			</div>
			<ModalComponent
				show={isFeedBackModalOpen}
				onHide={() => setIsFeedBackModalOpen(false)}
				modalTitle={"Feedback"}
				modalContent={<div>
					<RatingComponent
						setIsFeedBackModalOpen={setIsFeedBackModalOpen}
						isToOpen={false}
						value={value}
						setValue={setValue}
						feedback={feedback}
						setFeedback={setFeedback}
					/>
				</div>}
				modalFooter={<div
					style={{
						display       : "flex",
						justifyContent: "flex-end",
						alignItems    : "center"
					}}
				>
					<MainButton
						type="scale"
						direction="bottom"
						style={{ position: "relative", marginRight: "10px" }}
						offset={matchesXS ? 0 : 20}
						scale={1.04}>
						<Button
							className="sh-module-intro-enroll-now-btn"
							variant="outlined"
							color="secondary"
							onClick={() => setIsFeedBackModalOpen(false)}
						>
              Cancel
						</Button></MainButton>&nbsp;
					<MainButton
						type="scale"
						direction="bottom"
						style={{ position: "relative", marginRight: "10px" }}
						offset={matchesXS ? 0 : 20}
						scale={1.04}>
						<Button
							className="sh-module-intro-enroll-now-btn"
							variant="contained"
							color="secondary"
							onClick={(e: any) => handleSubmitFeedback(e)}
						>
              Submit
						</Button>
					</MainButton>
				</div>}
			/>
		</>
	</>}</>;

};

export default Video;