import React, { useState, useEffect } from "react";
// import { useTheme } from "@mui/material/styles";
import {
	Grid,
	Button,
	useTheme,
	Container,
	useMediaQuery
} from "@mui/material";
import { useNavigate } from "react-router-dom";

// components
import SkillLevel from "./SkillLevel";
import MainButton from "../../../components/UI/extend/MainButton";
import CustomLoader from "../../../components/UI/cards/CustomLoader";
// Store
import { useDispatch, useSelector } from "../../../store";
import { setUser } from "../../../store/slices/userSlice";
// Api
import { APIUrl } from "../../../api/endPoints";
import ApiRequest from "../../../api/ApiRequest";
// Config
import config from "../../../config/appConfig";
import { auth } from "../../../firebase";

const IntroPage = () => {

	const theme = useTheme();
	const user = auth.currentUser;
	const matchesXS = useMediaQuery(theme.breakpoints.down("md"));
	const { id } = useSelector((state: any) => state.user);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [ selectedSkillLevel, setSelectedSkillLevel ] = useState<any>("");
	const [ isUserDataLoading, setIsUserDataLoading ] = useState(true);

	let isDataFetched = false;

	useEffect(() => {

		if (isDataFetched && id !== "") return;

		isDataFetched = true;
		setIsUserDataLoading(true);

		ApiRequest("get", APIUrl.getUserInfo).then((response: any) => {

			if (response.skillLevel !== "") navigate(config.routes.course);
			dispatch(setUser({ user: response }));
			setIsUserDataLoading(false);

		}).catch((error: any) => {

			navigate(config.routes.landingHome);
			console.error(error);
			setIsUserDataLoading(false);
			

		});


	}, [ user ]);

	const handleContinue = () => {

		const payLoad = {
			"skillLevel": selectedSkillLevel
		};

		setIsUserDataLoading(true);

		ApiRequest("post", APIUrl.updateUserSkill, payLoad).then(() => {

			navigate(config.routes.course, { state: { fromIntro: true, skill: selectedSkillLevel } });
			setIsUserDataLoading(false);

		}).catch(error => {

			console.error(error);
			setIsUserDataLoading(false);

		});

	};

	return <> {isUserDataLoading ? <CustomLoader /> :
		<Container>
			<SkillLevel setSelectedSkillLevel={setSelectedSkillLevel} />
			<Grid item xs={12} className="intro-page-next-btn-con">
				<MainButton
					type="scale"
					direction="bottom"
					offset={matchesXS ? 0 : 20}
					scale={0.9}>
					<Button
						variant="contained"
						color="primary"
						sx={{
							borderRadius: "50px",
							fontSize    : "1.2rem"
						}}
						size={matchesXS ? "small" : "medium"}
						onClick={handleContinue}>

            Continue
					</Button>
				</MainButton>
			</Grid>
		</Container>
	}
	</>

	;

};

export default IntroPage;
