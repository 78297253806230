
import React from "react";
import AuthMode from "../Utils/RouteSentry/AuthMode";
import MainLayout from "../components/Layout/MainLayout";
import config from "../config/appConfig";
import ListCourse from "../views/Course/ListCourse";
import SoundAnalysis from "../views/soundAnalyzer";
import UserProfile from "../views/user/userProfile";
import EditProfile from "../views/user/editUserProfile";
import IntroPage from "../views/user/UserIntro/userIntro";
import Home from "../views/LandingPage/Home";
import ListModules from "../views/Modules/ListModules";
import ModuleIntro from "../views/Modules/ModuleIntro";
import Payment from "../views/Payment/Payment";
import Video from "../views/Videos/Video";
import AdminListLesson from "../views/Admin/Lessons/ListLessons";
import AdminLessons from "../views/Admin/Lessons/AddLesson";
import Dashboard from "../views/Admin/dashboard";
import ModuleLists from "../views/Admin/Module/ModuleLists";
import AdminLevels from "../views/Admin/Levels/ListLevels";
import AdminCourses from "../views/Admin/Courses/ListCourses";
import AddModulePage from "../views/Admin/Module/AddModulePage";
import AddLevelPage from "../views/Admin/Levels/AddLevelPage";
import EditCourse from "../views/Admin/Courses/EditCourse";
import Announcement from "../views/Announcements/Announcement";

const Routes = config.routes;


const privateRoutes: any = {
	path: "/",
	element:
		<AuthMode>
			<MainLayout />
		</AuthMode>
	,
	children: [
		{
			path   : Routes.landingHome,
			element: <Home />
		},
		{
			path   : Routes.course,
			element: <ListCourse />
		},
		{
			path   : Routes.soundAnalysis,
			element: <SoundAnalysis />
		},
		{
			path   : Routes.userProfile,
			element: <UserProfile />
		},
		{
			path   : Routes.editProfile,
			element: <EditProfile />
		},
		{
			path   : Routes.userIntro,
			element: <IntroPage />
		},
		{
			path   : Routes.modules,
			element: <ListModules />
		},
		{
			path   : Routes.moduleIntro,
			element: <ModuleIntro />
		},
		{
			path   : Routes.payment,
			element: <Payment />
		},
		{
			path	  : Routes.video,
			element: <Video />
		},
		{
			path   : Routes?.listLessons,
			element: <AdminListLesson />
		},
		{
			path   : Routes?.lessons,
			element: <AdminLessons />
		},
		{
			path	  : Routes.dashboard,
			element: <Dashboard />
		},
		{
			path	  : Routes.listModules,
			element: <ModuleLists />
		},
		{
			path   : Routes.listLevels,
			element: <AdminLevels />
		},
		{
			path   : Routes.listCourse,
			element: <AdminCourses />
		},
		{
			path   : Routes.addModulePage,
			element: <AddModulePage />
		},
		{
			path   : Routes.addLevelPage,
			element: <AddLevelPage />
		},
		{
			path   : Routes.editCourse,
			element: <EditCourse/>
		},
		{
			path   : Routes.announcement,
			element: <Announcement />
		}
	]
};
export default privateRoutes;
