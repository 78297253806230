import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

// inbuilt components imports
import Container from "@mui/material/Container";
import { Row, Col } from "react-bootstrap";
import { Typography, Button } from "@mui/material";
import { useTheme } from "@mui/material";
// import MainButton from "../../components/UI/extend/MainButton";

// import CurrencyRupeeOutlinedIcon from "@mui/icons-material/CurrencyRupeeOutlined";

// custom components imports
import Back from "../../components/UI/Back";
import config from "../../config/appConfig";

const ModuleIntro = () => {

	const locationState = useLocation().state;
	const navigate = useNavigate();
	const theme: any = useTheme();
	// const matchesXS = useMediaQuery(theme.breakpoints.down("md"));

	const moduleData = JSON.parse(locationState?.moduleData || "{}");

	moduleData["sampleVideoUrl"] = "https://s3-us-west-2.amazonaws.com/s.cdpn.io/148422/Vienna-SD.mp4";

	return (
		<Container className="sh-module-intro-container">
			<div>
				<Back
					props={{
						route  : config?.routes?.course,
						state  : { id: moduleData?.courseId, name: moduleData?.courseType },
						toolTip: "Back to Modules",
						text   : "Back to Courses"
					}}
				/>
			</div>
			{/* <div className="sh-module-intro-container" style={{ paddingLeft: "3em", paddingRight: "3em" }}> */}
			<Row>
				<Typography
					variant="h1"
					gutterBottom
					className="sh-module-intro-left-side-title-content"
					style={{ marginTop: "1em" }}
				>
					{moduleData?.moduleName}
				</Typography>
			</Row>
			<Row
				className="sh-module-intro-row"
			>
				<Col className="sh-module-intro-column-left">
					<div
						className="sh-module-intro-left-side-description-content">
						<Typography variant="h4"
							style={{
								color       : theme?.palette?.secondary?.main,
								marginBottom: "1em"
							}}
						>About</Typography>
						<Typography
							variant="subtitle2"
							gutterBottom
							style={{
								marginBottom: "1em"
							}}
							
						>
							{moduleData?.moduleDesc}
              
						</Typography>
						<div style={{
							marginBottom: "1em"
						}}>

							<Typography
								variant="subtitle2"
							>
								Purchase the entire Module at just: Rs. {moduleData?.modulePrice}/.&nbsp;&nbsp;<span
									style={{ fontSize: "0.8em", fontStyle: "italic" }}
								>
									Exclusive of GST
								</span>
								<br />
								{
									moduleData?.packageId &&
									<div>
										Purchase the first 3 Modules at just Rs. {moduleData?.packageId?.price}/. &nbsp;&nbsp;
										<span
											style={{ fontSize: "0.8em", fontStyle: "italic" }}>
											Exclusive of GST
										</span>
									</div>
								}
							</Typography>
							<div
								style={{
									marginTop: "1em"
								}}
							>
								<Button
									className="sh-module-intro-enroll-now-btn"
									variant="contained"
									color="secondary"
									onClick={() => { navigate(config?.routes?.payment, { state: { moduleData: JSON.stringify(moduleData) } }); }}
								>
										Enroll Now
								</Button>
							</div>
						</div>
					</div>
				</Col>
				<Col className="sh-module-intro-column-right">
					<Typography variant="h4"
						style={{
							color       : theme?.palette?.secondary?.main,
							marginBottom: "1em"
						}}
					>Skills Covered:</Typography>
					{
						moduleData?.otherInfo?.skillSet.map((skill: any, index: number) => {

							return (
								<Typography key={index} variant="subtitle2">{skill}</Typography>
							);

						})
                
					}
					
				</Col>
			</Row>
			<Row
				style={{
					marginBottom: "1em"
				}}
			>
				<Col>
					<video
						autoPlay
						controls
						controlsList="nodownload noremoteplayback noplaybackrate"
						className="sh-module-intro-video-section-con">
						<source src={`${config.appDomain}/${moduleData.introVideo}`} type="video/mp4" />
					</video>
				</Col>

			</Row>
			{/* </div> */}
		</Container>
	);

};

export default ModuleIntro;