import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Back from "../../../components/UI/Back";
import config from "../../../config/appConfig";
import { Button, Typography, Container, useTheme, useMediaQuery } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Row, Col } from "react-bootstrap";
import ApiRequest from "../../../api/ApiRequest";
import { APIUrl } from "../../../api/endPoints";
import { useNavigate } from "react-router-dom";
// import DeleteModal from "../../../components/UI/DeleteModal";
import toast from "react-hot-toast";
import { FaCloudUploadAlt, FaPen, FaTrash } from "react-icons/fa";
import Select from "../../../components/UI/Select";
import ModalComponent from "../../../components/modal";
import MainButton from "../../../components/UI/extend/MainButton";
import DropZone from "../../../components/UI/DropZone";
import { FaFileUpload } from "react-icons/fa";
import axios from "axios";
import TooltipComponent from "../../../components/UI/Tooltip";
import { FaRotateRight } from "react-icons/fa6";
import { FaExclamationTriangle } from "react-icons/fa";


const ListLessons = () => {

	interface Lesson {
		courseName: string;
		lessonName: string;
		levelName: string;
		moduleName: string;
		[key: string]: any;
	}

	const theme: any = useTheme();
	// eslint-disable-next-line @typescript-eslint/no-var-requires, no-undef
	const { DataTableStyles } = require("../../../Utils/utils");
	const matchesXS = useMediaQuery(theme.breakpoints.down("md"));
	const [ data, setData ] = useState([]);
	const [ isLoading, setIsLoading ] = useState<boolean>(false);
	const [ isListCourseDeleteModelOpen, setIsListCourseDeleteModelOpen ] = useState<boolean>(false);
	const navigate = useNavigate();
	const [ deletedLessonName, setDeletedLessonName ] = useState<any>({});
	const [ selectedCourse, setSelectedCourse ] = useState(null);
	const[ courseOptions, setCourseOptions ] = useState<any>([]);
	const [ selectedLevel, setSelectedLevel ] = useState(null);
	const [ levelOptions, setLevelOptions ] = useState<any>([]);
	const [ selectedModule, setSelectedModule ] = useState(null);
	const [ moduleOptions, setModuleOptions ] = useState<any>([]);
	const [ isUploadReferenceModalOpen, setIsUploadReferenceModalOpen ] = useState<boolean>(false);
	const [ refFile, setRefFile ] = useState<any>(null);
	const [ selectedRow, setSelectedRow ] = useState<any>(null);
	

	useEffect(() => {

		fetchAllLessons();

	}, []);
	
	useEffect(() => {

		const courses = Array.from(new Set(data.map((lesson:any) => lesson.courseName)));
		setCourseOptions(courses.map(course => ({ label: course, value: course })));
	
	}, [ data ]);

	
	useEffect(() => {

		setLevelOptions([]);
		setSelectedLevel(null);
		if (selectedModule) {

			const filteredLevels = data
				.filter((lesson: Lesson) => lesson.moduleName === selectedModule)
				.map((lesson: Lesson) => lesson.levelName);
			
			const uniqueLevels = Array.from(new Set(filteredLevels)).sort();
	
			setLevelOptions(uniqueLevels.map(levelName => ({ label: levelName, value: levelName })));
		
		}
		
	
	}, [ selectedModule, data ]);


	const fetchAllLessons = () => {

		setIsLoading(true);
		
		ApiRequest("get", APIUrl.getAllLessons).then((response: any) => {

			setIsLoading(false);
			const lessons = response.data;
			setData(lessons);
			const uniqueCourses = Array.from(new Set(lessons.map((lesson: Lesson) => lesson.courseName)));
			const uniqueLevels = Array.from(new Set(lessons.map((lesson: Lesson) => lesson.levelName)));
			const uniqueModules = Array.from(new Set(lessons.map((lesson: Lesson) => lesson.moduleName)));

			setCourseOptions(uniqueCourses.map(courseName => ({ label: courseName, value: courseName })));
			setLevelOptions(uniqueLevels.map(levelName => ({ label: levelName, value: levelName })));
			setModuleOptions(uniqueModules.map(moduleName => ({ label: moduleName, value: moduleName })));

		}).catch((error: any) => {

			console.error("Error fetching data", error);
			
			setIsLoading(false);
			setData([]);

		});

	};


	const cellRenderer = (text: any) => {

		const cellText = String(text);

		return <> {
			cellText?.length > 25 ? <>
				<TooltipComponent text={cellText} placement="top-start">
					<div>{cellText.substring(0, 25) + "..."}</div>
				</TooltipComponent>
			</> : cellText
		}
		</>;
	
	};


	const editLesson = (row: any) => {

		navigate(`${config.routes.lessons}#edit-lesson`, { state: row });

	};

	const generalSortFunction = (key: string) => (a: any, b: any) => {

		const valueA = a[key];
		const valueB = b[key];
	  
		// Handle cases where values are not present
		if (valueA === undefined || valueB === undefined) return 0;
	  
		// If both values are numbers
		if (!isNaN(valueA) && !isNaN(valueB)) {

		  return parseFloat(valueA) - parseFloat(valueB);
		
		}
	  
		// Otherwise, treat them as strings
		return valueA.toString().toLowerCase().localeCompare(valueB.toString().toLowerCase());
	  
	};

	
	const handlePublishVideo = (params: any) => {

		let data: any = {};

		if(params?.publishedStatus == "not_published") {

			const userConfirm = window.confirm("Are you sure you want to publish this video?");

			if (!userConfirm) return;

			data = {
				lessonId   : params._id,
				isPublished: "published"
			};
			
		} else {

			const userConfirm = window.confirm("Are you sure you want to un-publish this video?");

			if (!userConfirm) return;

			data = {
				lessonId   : params._id,
				isPublished: "not_published"
			};

		}
		
		ApiRequest("post", APIUrl.publishOrUnPublishLesson, data).then(() => {

			params?.publishedStatus == "not_published" ? toast.success("Video published successfully!") : toast.success("Video un-published successfully!");

			fetchAllLessons();

		}).catch((error: any) => {

			params?.publishedStatus == "not_published" ? toast.error("Error publishing video") : toast.error("Error un-publishing video");

			console.error("Error publishing video", error);

		});

	};
    
	const columns: any = [
		{
			name        : "Lesson Name",
			selector    : (row:any) => cellRenderer(row.lessonName ? row.lessonName : ""),
			cell        : (row:any) => cellRenderer(row.lessonName ? row.lessonName : ""),
			Grow        : 1,
			sortable    : true,
			sortFunction: generalSortFunction("lessonName"),
			width   	   : "120px"

		},
		{
			name        : "Level Name",
			selector    : (row:any) => row.levelName,
			Grow        : 1,
			cell        : (row:any) => row.levelName,
			sortable    : true,
			sortFunction: generalSortFunction("levelName"),
			width   	   : "100px"
		
		},
		{
			name        : "Course Name",
			selector    : (row:any) => cellRenderer(row.courseName ? row.courseName : ""),
			Grow        : 1,
			cell        : (row:any) => cellRenderer(row.courseName ? row.courseName : ""),
			eclipsed    : true,
			sortable    : true,
			sortFunction: generalSortFunction("courseName"),
			width   	   : "150px"
            
		},
		{
			name        : "Module Name",
			selector    : (row:any) => row.moduleName,
			Grow        : 1,
			cell        : (row:any) => cellRenderer(row.moduleName ? row.moduleName : ""),
			eclipsed    : true,
			sortable    : true,
			sortFunction: generalSortFunction("moduleName"),
			width   	   : "150px"
            
		},
		{
			name        : "Upload Status",
			selector    : (row:any) => row.moduleName,
			Grow        : 1,
			cell        : (row:any) => cellRenderer(row.uploadStatus ? row.uploadStatus : ""),
			eclipsed    : true,
			sortable    : true,
			sortFunction: generalSortFunction("uploadStatus"),
			width   	   : "120px"
            
		},
		{
			name        : "Published Status",
			selector    : (row:any) => row.moduleName,
			Grow        : 1,
			cell        : (row:any) => cellRenderer(row.publishedStatus ? row.publishedStatus : ""),
			eclipsed    : true,
			sortable    : true,
			sortFunction: generalSortFunction("publishedStatus"),
			width   	   : "120px"
            
		},
		
		{
			name    : "Created At",
			// selector: (row:any) => row.createdAt ? new Date(row.createdAt).toLocaleString() : "",
			sortable: true,
			center  : true,
			selector: (row: any) => {

				const createdAt = row.createdAt ? new Date(row.createdAt).toLocaleString() : "";
				
				return createdAt ?
				  <TooltipComponent text={createdAt} placement="top">
						<div>{createdAt}</div>
				  </TooltipComponent>
				 : "";
			  
			},
			grow : 1,
			width: "140px"
		},
		{
			name    : "Updated At",
			// selector: (row:any) => row.updatedAt ? new Date(row.updatedAt).toLocaleString() : "",
			selector: (row: any) => {

				const updatedAt = row.updatedAt ? new Date(row.updatedAt).toLocaleString() : "";
				
				return updatedAt ?
				  <TooltipComponent text={updatedAt} placement="top">
						<div>{updatedAt}</div>
				  </TooltipComponent>
				 : "";
			  
			},
			center  : true,
			grow    : 1,
			sortable: true,
			width   : "140px"
		},
		{
			name  : "Action",
			center: true,
			cell  : (params: any)=>
				<div className="sh-fontawesome-edit-delete">

					<TooltipComponent text="Edit Lesson" placement="top-start" >
						<span
							className="sh-fontawesome-edit"
						>
							<FaPen size={15} onClick={()=> editLesson(params)} color={theme.palette.text.primary} />
						</span>
					</TooltipComponent>

					<TooltipComponent text="Delete Lesson" placement="top-start" >
						<span
							className="sh-fontawesome-edit"
						>
							<FaTrash size={15} onClick={() => {

								setIsListCourseDeleteModelOpen(true);
								setDeletedLessonName(params);
						
							}} color={theme.palette.text.primary} />
						</span>
					</TooltipComponent>

					<TooltipComponent text={params?.publishedStatus == "not_published" ? "Publish the Video" : "Un-publish the video"} placement="top-start"
					>
						<span
							className="sh-fontawesome-edit"
						>
							<FaCloudUploadAlt
								onClick={() => handlePublishVideo(params)}
								size={20}
								style= {{ cursor: "pointer" }}
								color={params?.publishedStatus == "not_published" ? theme?.palette?.success.main : theme?.palette?.grey[500]} />
						</span>
					</TooltipComponent>
					<TooltipComponent text={params?.publishedStatus == "published" && params?.uploadStatus == "success" ? "Upload Reference Document" : "Reference document can be uploaded only after video is published and uploaded"} placement="top-start" >
						<span
							className="sh-fontawesome-edit"
						>
							<FaFileUpload
								onClick={() => {

									if (params?.publishedStatus != "published") return;

									setSelectedRow(params);
									setIsUploadReferenceModalOpen(true);
						
								}}
								size={20}
								style= { params?.publishedStatus == "published" && params?.uploadStatus == "success" ? { cursor: "pointer" } : { cursor: "not-allowed" } }
								color={params?.publishedStatus == "published" && params?.uploadStatus == "success" ? theme?.palette?.success.main : theme?.palette?.grey[500]}
							/>
						</span>
					</TooltipComponent>
				</div>,
			width: "100px"
            
		}
    
	];

	const handleClickAddLessons = () => {

		navigate(config.routes.lessons);

	};
	
	const handleCourseSelect = (selectedOption:any) => {

		const selectedCourse = selectedOption ? selectedOption.value : null;
		setSelectedCourse(selectedCourse);
		setSelectedModule(null);
		setSelectedLevel(null);
	
		if (selectedCourse) {

			const modules = Array.from(new Set(
				data.filter((lesson:any) => lesson.courseName === selectedCourse)
					.map((lesson:any) => lesson.moduleName)
			));
			setModuleOptions(modules.map(module => ({ label: module, value: module })));
		
		}
	
	};
	const handleModuleSelect = (selectedOption:any) => {

		const selectedModule = selectedOption ? selectedOption.value : null;
		setSelectedModule(selectedModule);
		setSelectedLevel(null);
		setLevelOptions([]);
		if (selectedModule) {

			const levels = Array.from(new Set(
				data.filter((lesson:any) => lesson.moduleName == selectedModule)
					.map((lesson:any) => lesson.levelName)
			)).sort();
			setLevelOptions(levels.map(level => ({ label: level, value: level })));
		
		}
	
	};
	const handleLevelSelect = (selectedOption:any) => {

		setSelectedLevel(selectedOption ? selectedOption.value : null);
	
	};

	const handleDelete = () => {

		toast.success(`Lesson Name "${deletedLessonName.lessonName}" deleted successfully!`);

		const payload = {
			"lessonId": deletedLessonName._id
		};

		ApiRequest("post", APIUrl.deleteLesson, payload).then(() => {

		  // Update your state or call the delete API here
			setIsListCourseDeleteModelOpen(false);

			fetchAllLessons();

		}).catch((error: any) => {

			toast.error(`Error deleting Lesson Name "${deletedLessonName.lessonName}"`);
			console.error("Error deleting lesson", error);

		});

	};

	const handleRotate = () => {
		
		fetchAllLessons();
	
	};

	const uploadPdfFile = (acceptedFiles: any) => {

		if (acceptedFiles && acceptedFiles.length > 0) {
		
			if (acceptedFiles[0]?.type !== "application/pdf") {

				return;

			}

			setRefFile(acceptedFiles[0]);

		}

	};

	const UploadReferenceDocContent = () => {

		return <div>
			<div className="sh-upload-reference-doc-content">
				<div className="sh-upload-reference-doc-content-file">
					{/* Add Dropzone component which allows only pdf file */}
					<DropZone
						dropMedia={uploadPdfFile}
						acceptFileType={[ "application/pdf" ]}
						isUploadedMediaValid={refFile ? true : false}
						uploadPostMediaName={refFile?.name}
						accept={ { "application/pdf": [] }}
						disabled={!selectedRow || selectedRow?.uploadStatus !== "success" || selectedRow?.publishedStatus !== "published"}
						disabledMessage={"Please select the level"}
						message={refFile ? "Click Submit to upload" : "Please upload pdf file"}
					/>
				</div>
			</div>
		</div>;

	};
	

	const closeModal = () => {

		setIsUploadReferenceModalOpen(false);
		setSelectedRow(null);

	};

	const handleSubmitFileUpload = (selectedRow: any, refFile: any) => {

		if (!selectedRow || !refFile) return;

		const payload = {
			"lessonData": {
				"_id"       : selectedRow._id,
				"lessonName": selectedRow.lessonName,
				"courseId"  : selectedRow.courseId,
				"moduleId"  : selectedRow.moduleId,
				"levelId"   : selectedRow.levelId,
				"fileName"  : refFile.name,
				"fileType"  : refFile.type
			}
		};

		ApiRequest("post", APIUrl.getSignedUrlToUploadRefDoc, payload).then((response: any) => {

			const signedUrl = response?.url;
			const file = refFile;

			const options = {
				headers: {
					"Content-Type": file.type
				}
			};

			if (signedUrl) {

				axios.put(signedUrl, file, options).then(() => {
	
					toast.success("Reference document uploaded successfully!");
					closeModal();
					fetchAllLessons();
	
				}).catch((error: any) => {
	
					toast.error("Error uploading reference document");
					console.error("Error uploading reference document", error);
	
				});

			}

		}).catch((error: any) => {

			console.error("Error uploading reference document", error);

		});

	};


	return (
    
		<Container >
			<Row>
				<Col md={6} sm={6} xl={6} xs={6} >
					<div className="admin-dash-board-back-title-con" style={{ marginBottom: theme.spacing(2) }}
					>
						<Back
							props={{
								route  : config.routes.dashboard,
								state  : null,
								toolTip: "Back to Dashboard",
								text   : "Back to admin dashboard"
							}}
						/>
					</div>
				</Col>
				<Col
					md={6} sm={6} xl={6} xs={6}
					className="sh-refresh-rotate-icon">
					<Button
						variant="outlined"
						style={{ backgroundColor: theme?.palette?.secondary.main, color: theme.palette.text.primary }}
						className="sh-module-intro-add-btn sh-list-lesson-btn"
						 startIcon={<AddIcon />} onClick={handleClickAddLessons}>Add</Button>&nbsp;&nbsp;&nbsp;
					<Button style={{ backgroundColor: theme?.palette?.secondary.main, color: theme.palette.background.paper }} onClick={handleRotate} className="sh-list-lesson-refresh-icon-button">
						<FaRotateRight color={theme.palette.text.primary} />
					</Button>
				</Col>
			</Row>
			<Row>
				<Col md={12} sm={12} xl={12} xs={12} >
					<div className="admin-dash-board-back-title-con" style={{ marginBottom: theme.spacing(2) }}
					>
						<Typography variant="h2">
						List Lessons
						</Typography>
					</div>
				</Col>
			</Row>
			{/* <Row className="sh-admin-modules-list-page-con">
				<Col className="back-button-width">
					<Back
						props={{
							route  : config.routes.dashboard,
							state  : null,
							toolTip: "Back to Course List",
							text   : "Admin Dashboard"
						}}
					/>
				</Col>
				<Col className="sh-admin-modlues-list-page-title-content" >
					<Typography variant="h2" ></Typography>
				</Col>
				<Col className="list-lesson-add-button module-list-add-width">
					
				</Col>
				<Col
					className="sh-list-lesson-refresh-icon-col">
					<Button onClick={handleRotate} style={{ backgroundColor: theme?.palette?.secondary.main, color: theme.palette.background.paper }}
						className="sh-list-lesson-refresh-icon-button">
						<FaRotateRight color={theme.palette.text.primary} />
					</Button>

				</Col>
        
			</Row> */}
			<Row
				className="sh-list-lesson-select-lesson">
				<Col md={4} sm={4} xl={4} xs={4}
					className="sh-list-lesson-padding-right">
					<Row>
						<Select
							label="Course Name"
							options={courseOptions}
							value={courseOptions?.find((option:any) => option.value === selectedCourse)}
							handleChange={handleCourseSelect}
							disabled={false}
							placeholder = "Select the Course"
						/>
					</Row>
				</Col>
				<Col md={4} sm={4} xl={4} xs={4}
					className="sh-list-lesson-padding-right">
					<Row>
						<Select
							label="Module Name"
							options={moduleOptions}
							value={moduleOptions?.find((option:any) => option.value === selectedModule ) || null}
							handleChange={handleModuleSelect}
							placeholder = "Select the Module"
						/>
					</Row>
				</Col>
				<Col md={4} sm={4} xl={4} xs={4}
					className="sh-list-lesson-padding-right">
					<Row>
						<Select
							label="Level Name"
							options={levelOptions}
							value={levelOptions?.find((option:any) => option.value === selectedLevel ) || null}
							handleChange={handleLevelSelect}
							placeholder = "Select the Level"
						/>
					</Row>
				</Col>
			</Row>
			<Row className="module-list-data-table">
				<Col className="module-list-data-table-col">
					<DataTable
						columns={columns}
						progressPending={isLoading}
						progressComponent={<div className="sh-table-progress-con">Loading...</div>}
						// data={data}
						data = {data.filter((d:any) =>
							(selectedCourse === null || d.courseName === selectedCourse) &&
							(selectedLevel === null || d.levelName === selectedLevel) &&
							(selectedModule === null || d.moduleName === selectedModule)
						)
						}
						customStyles={DataTableStyles}
						fixedHeader = {true}
						pagination
						paginationPerPage={10}
						paginationRowsPerPageOptions={ [
							5, 10, 15, 20
						] }

					/>
				</Col>
			</Row>
			<ModalComponent
				show={isListCourseDeleteModelOpen}
				size="lg"
				closeButton={true}
				onHide={() => setIsListCourseDeleteModelOpen(false)}
				icon={FaExclamationTriangle}
				modalTitle="Confirm Delete"
				modalContent={<Typography variant="h3" style={{ color: theme.palette.text.primary }}>
					Are you sure you want to delete this {deletedLessonName.lessonName} ?</Typography>}
				modalFooter={
					<div className="" style={{ display: "flex", flexDirection: "row" }}>
						<div style={{ paddingRight: "10px" }}>
							<Button variant="outlined" onClick={() => setIsListCourseDeleteModelOpen(false)} style={{ paddingRight: "10px" }}>Cancel</Button>
						</div>
						<div>
							<Button variant="contained" color="error" onClick={handleDelete}>Delete</Button>
						</div>
					</div>
				}
			/>
			<ModalComponent
				show={isUploadReferenceModalOpen}
				closeButton={true}
				onHide={() => closeModal()}
				modalTitle={<div>
					<Typography variant="h4" className="sh-modal-title">Upload Reference Document for &nbsp;<span style={{ color: theme.palette.secondary.main, textTransform: "capitalize", lineHeight: "1.8" }}>{selectedRow?.lessonName}</span></Typography>
				</div>}
				modalContent={ <UploadReferenceDocContent />}
				modalFooter={<div
					style={{
						display       : "flex",
						justifyContent: "flex-end",
						alignItems    : "center"
					}}
				>
					<MainButton
						type="scale"
						direction="bottom"
						style={{ position: "relative", marginRight: "10px" }}
						offset={matchesXS ? 0 : 20}
						scale={1.04}>
						<Button
							className="sh-module-intro-enroll-now-btn"
							variant="outlined"
							color="secondary"
							onClick={() => closeModal()}
						>Cancel
						</Button>
					</MainButton>
					<MainButton
						type="scale"
						direction="bottom"
						style={{ position: "relative" }}
						offset={matchesXS ? 0 : 20}
						scale={1.04}>
						<Button
							className="sh-module-intro-enroll-now-	btn"
							variant="contained"
							color="secondary"
							onClick={() => handleSubmitFileUpload(selectedRow, refFile)}
							disabled={!selectedRow || !refFile}
						>
              				Submit
						</Button>
					</MainButton>
					
				</div>}
			/>

		</Container>
        
	);

};

export default ListLessons;
