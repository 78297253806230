import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import _ from "lodash";
import ShakaPlayer from "../../components/UI/ShakaPlayer/";
import ApiRequest from "../../api/ApiRequest";
import { APIUrl } from "../../api/endPoints";

const VideoPlayer = ({
	videoId,
	setVideoData,
	currentLesson,
	setCurrentLesson,
	updateUserProgress,
	currentLessonId,
	levelData,
	setCurrentLessonId,
	currentLevelId,
	setCurrentLevelId,
	isVideoDataLoading,
	player,
	setPlayer,
	shakaRef,
	videoRef,
	setExpanded
}: any) => {

	const [ videoUrl, setVideoUrl ] = useState("");
	const [ isVideoLoading, setIsVideoLoading ] = useState(true);

	useEffect(() => {

		const fetchData = async () => {

			try {

				if (videoId) {

					setIsVideoLoading(true);
					const videoData: any = await ApiRequest("post", APIUrl.getSignedUrlVideo, { id: videoId });
					setVideoData(videoData);
					setIsVideoLoading(false);
					setVideoUrl(videoData.playbackUrl);
					
				}

			} catch (error) {

				setIsVideoLoading(false);
				setVideoData({});
				console.error("Error", error);

			}

		};

		fetchData();

		return () => {

			setVideoUrl("");

		};

	}, [ videoId ]);

	const updateUserProgressAndNavigate = async (currentTime: any) => {

		try {

			await updateUserProgress(currentLesson?.lessonId, currentTime, currentLesson);

			const currentLevelIndex = _.findIndex(levelData, { levelId: currentLevelId });
			const totalLessonCount = levelData[currentLevelIndex].lessons.length;
			const currentLessonIndex = _.findIndex(levelData[currentLevelIndex].lessons, {
				lessonId: currentLessonId
			});

			if (currentLessonIndex + 1 < totalLessonCount) {

				setCurrentLessonId(levelData[currentLevelIndex].lessons[currentLessonIndex + 1].lessonId);
				setCurrentLesson(levelData[currentLevelIndex].lessons[currentLessonIndex + 1]);

			} else {

				const nextLevelIndex = currentLevelIndex + 1;

				if (nextLevelIndex < levelData.length) {

					setCurrentLevelId(levelData[nextLevelIndex].levelId);
					setCurrentLessonId(levelData[nextLevelIndex].lessons[0].lessonId);
					setCurrentLesson(levelData[nextLevelIndex].lessons[0]);
					setExpanded(nextLevelIndex);

				} else {
					
					// console.info("All levels completed");

				}
			
			}
		
		} catch (error) {

			console.error("Error updating progress and navigating", error);
		
		}
	
	};

	return (
		<div>
			{isVideoLoading && isVideoDataLoading ?
				<div className="video-player-main-con">
					<CircularProgress />
				</div>
				:
				<div className="video-player-con">
					{videoUrl && videoId ? <div style={{
						width: "100%"
					
					}}>
						<ShakaPlayer
							className="shaka-player-container"
							ref={shakaRef}
							videoRef={videoRef}
							player={player}
							setPlayer={setPlayer}
							autoPlay
							src={videoUrl}
							watchDuration={currentLesson?.durationWatched}
							updateUserProgressAndNavigate={updateUserProgressAndNavigate}
							chromeless={false} />
					</div> :
						<div>
							<h2>{
								!videoId ? "No video found" : <div className="video-player-main-con">
									<CircularProgress />
								</div>
							}</h2>
						</div>
					}
				</div>
			}
		</div>
	);

};

export default VideoPlayer;
