import React, { useState, useEffect } from "react";
// import userCoverPhoto from "../../assets/images/coverPhoto.jpg";

// import userImage from "../../assets/icons/user-round.svg";
import Container from "@mui/material/Container";
import { Row, Col } from "rsuite";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import { useNavigate } from "react-router-dom";
import config from "../../config/appConfig";
import { useSelector } from "../../store";
import { useMediaQuery, Button, Grid, Rating } from "@mui/material";
import ApiRequest from "../../api/ApiRequest";
import { APIUrl } from "../../api/endPoints";
import MainButton from "../../components/UI/extend/MainButton";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";

const UserProfile = () => {

	const navigate = useNavigate();
	const theme: any = useTheme();
	const [ profileMainTab, setProfileMainTab ] = useState("one");
	const [ profileSubTab, setProfileSubTab ] = useState("one");
	const [ moduleData, setModuleData ] = useState([]);
	const [ hoveredCardIndex, setHoveredCardIndex ] = useState(-1);
	const { firstName, lastName, profileIconPath, userType } = useSelector((state: any) => state.user);
	const matchesXS = useMediaQuery(theme.breakpoints.down("md"));

	useEffect(() => {

		const payload = {
			isFromUserPage: true
		};

		ApiRequest("post", APIUrl.listAllModuleByCourseId, payload).then((response: any) => {

			setModuleData(response);


		}).catch((error: any) => {

			setModuleData([]);

			console.error(error);


		});

	}, []);

	const handleUserProfileOptions = (event: React.ChangeEvent<any>, newValue: string) => {

		setProfileMainTab(newValue);

	};

	const handleUserProfileCoursesOptions = (event: React.ChangeEvent<any>, newValue: string) => {

		setProfileSubTab(newValue);

	};

	return (
		<Container fixed>
			{/* <Row className="sh-user-profile-cover-image-con">
        {
          profileCoverPath ? <img className="sh-user-profile-cover-image" src={profileCoverPath} alt="" /> : <img className="sh-user-profile-cover-image" src={userCoverPhoto} alt="" />
        }
        
      </Row> */}
			<Row className="sh-user-profile-details-con" style={{ backgroundColor: theme?.palette?.background?.default }}>
				<Col className="sh-user-profile-image-con" style={{ backgroundColor: theme?.palette?.background?.default }}>
					{
						profileIconPath ? <img style={{ border: `2px solid ${theme?.palette?.secondary?.main}` }} className="sh-user-profile-image" src={profileIconPath} alt="" /> : <AccountCircleRoundedIcon style={{ fontSize: "5rem" }} />
					}
				</Col>
				<Col className="sh-user-profile-user-details-con">
					<Row>
						<Row className="sh-user-profile-name-type-con">
							<Col>
								<Typography variant="h4" sx={{ color: theme?.palette?.text?.dark }}>
									{firstName} {lastName}
								</Typography>
							</Col>
						</Row>
						<Typography className="sh-user-profile-tag-name" sx={{ color: theme?.palette?.text?.disabled }} variant="subtitle1" gutterBottom>
							{ userType ? userType == config.userTypes.systemAdmin ? "system admin" : userType : "" }
						</Typography>
					</Row>
				</Col>
			</Row>
			<Row>
				<TabContext value={profileMainTab}>
					<TabList
						textColor={theme?.palette?.primary?.main}
						onChange={handleUserProfileOptions}
						sx={{ backgroundColor: theme?.palette?.background?.default, borderTop: `1px solid ${theme?.palette?.divider}` }}
						indicatorColor="secondary"
					>
						<Tab label="Profile" value="one" />
						<Tab label="Courses" value="two" />
					</TabList>
					<Row>
						<TabPanel value="one" className="sh-user-profile-user-details-tabs-section" style={{ backgroundColor: theme?.palette?.background?.default }}>
							<Row className="sh-user-details-basic-info-edit-btn-con">
								<Col className="sh-user-details-basic-info-section">
									<Typography variant="h3" sx={{ color: theme?.palette?.text?.dark }}>
                    Basic Info
									</Typography>
								</Col>
								<Col className="sh-user-details-edit-btn-section">
									<Button
										variant="contained"
										color="secondary"
										size={matchesXS ? "small" : "medium"}
										onClick={() => navigate(config?.routes?.editProfile)}>Edit
									</Button>
									{/* <button style={{ color: theme?.palette?.text?.dark, backgroundColor: theme?.palette?.grey[50] }} className="sh-user-profile-basic-info-edit-button" onClick={() => navigate(config?.routes?.editProfile)} >Edit</button> */}
								</Col>
							</Row>
							<Row className="sh-user-profile-tab-section-container" style={{ borderTop: `1px solid ${theme?.palette?.divider}` }}>
								<Typography className="sh-user-profile-tab-section-details" variant="subtitle1" sx={{ color: theme?.palette?.text?.dark }}>
									<Col className="sh-user-profile-data-labels-section sh-user-profile-data-labels-section-padding">
                    First Name:
									</Col>
									<Col className="sh-user-profile-data-labels-section">
										{firstName}
									</Col>
								</Typography>
								<br />
								<Typography className="sh-user-profile-tab-section-details" variant="subtitle1" sx={{ color: theme?.palette?.text?.dark }}>
									<Col className="sh-user-profile-data-labels-section sh-user-profile-data-labels-section-padding">
                    Last Name:
									</Col>
									<Col className="sh-user-profile-data-labels-section">
										{lastName}
									</Col>
								</Typography>
							</Row>
						</TabPanel>
						<TabPanel className="sh-user-profile-user-details-tabs-section" value="two">
							<TabContext value={profileSubTab}>
								<TabList
									textColor={theme?.palette?.primary?.main}
									onChange={handleUserProfileCoursesOptions}
									indicatorColor="secondary"
									// sx={{ backgroundColor: theme?.palette?.background?.default, borderTop: `1px solid ${theme?.palette?.divider}` }}
								>
									<Tab label="My Courses" value="one" />
									<Tab label="My Certificates" value="two" />
								</TabList>
								<TabPanel value="one">
                 
								 <div>
								 <Row>
											<Col style={{ width: "100%" }} md={12} sm={12} xs={12}>
												{moduleData.length > 0 &&
								<Grid container spacing={2}>

									{moduleData && moduleData.map((course: any, index: any) => {

										return <>
											<Grid item
												xs={12}
												sm={6}
												md={3}
												lg={3}
												xl={3}
												key={index}
											>
												<div
													onMouseEnter={() => setHoveredCardIndex(index)}
													onMouseLeave={() => setHoveredCardIndex(-1)}
												>
													<MainButton
														type="scale"
														direction="bottom"
														style={{ position: "relative", cursor: "pointer" }}
														offset={matchesXS ? 0 : 20}
														scale={1.04}>
														
														<div
															style={{
																backgroundImage   : `url(${config.appDomain}/${course.moduleIcon})`,
																backgroundSize    : "cover",
																backgroundPosition: "center",
																minHeight         : "25vh",
																width             : "100%",
																borderRadius      : "1em",
																border            : "1px solid #000000",
																display           : "flex",
																alignItems        : "flex-end",
																position          : "relative"
															}}
															onClick={() => {

																navigate(config?.routes?.video, { state: { moduleData: JSON.stringify(course) } });
				
															}}
														>
															{
																hoveredCardIndex === index && (!course.unlocked || course.level == 0) && <div
																	className="sh-list-modules-over-lay"
																>
																	<Typography style={{ fontSize: "1.2em" }} variant="body1">{ course.level == 0 ? "No Content" : "Unlock Now" }</Typography>
																</div>
															}
															<div
																style={{ position: "absolute", top: "2%", left: "86%" }}
															>
																{
																	!course.unlocked && <LockOutlinedIcon />
																}
															</div>
															<div className="course-module-list-div-con">
																<Typography className="course-desc-con" sx={{
																	// color: theme?.palette?.text?.primary
																	color: theme?.palette?.text?.primary
																}} variant="h5">{course.moduleName}
																</Typography>
																<div className="sh-list-modules-lock-icon-videos-text-con">
																	<div>
																		<Typography className="course-duration-con"
																			style={{ color: theme?.palette?.text.secondary }}
																			variant="body1">
																			<span style={{ color: theme?.palette?.text.primary }}
																			>{course?.level ? course?.level : 0}</span > Videos</Typography>
																	</div>
																	<div>
																		<Rating
																			readOnly
																			defaultValue={course?.averageRating}
																			precision={0.5}
																		/>
																	</div>
																</div>

															</div>
														
														</div>
													
													</MainButton>
												</div>
											</Grid>
										</>;

									}

									)}
								</Grid>
												}
											</Col>
										</Row>
								 </div>
								</TabPanel>
								<TabPanel value="two">
                  My Certificates
								</TabPanel>
							</TabContext>
						</TabPanel>
					</Row>
				</TabContext>
			</Row>
		</Container>
	);

};

export default UserProfile;